.page-footer {
    z-index: 501;
    height: 100px;
    bottom: 0;
    font-size: 12px;
    letter-spacing: 1.4px;

    &-declaration {
        margin: 5px 5px 20px;
    }
    &-footer-nav {
        min-height: 25px;
    }
    .copyright {
        margin: 20px 5px;
    }
}

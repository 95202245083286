// @mixin
@mixin animation-name($name) {
    -webkit-animation-name: $name;
    -moz-animation-name: $name;
    -ms-animation-name: $name;
    -o-animation-name: $name;
    animation-name: $name;
}

@mixin animation-detail($name) {
    @if $name == shine {
        -webkit-animation-duration: 5s;
        -moz-animation-duration: 5s;
        -ms-animation-duration: 5s;
        -o-animation-duration: 5s;
        animation-duration: 5s;
    } @else if $name == slideIn {
        -webkit-animation-duration: 3s;
        -moz-animation-duration: 3s;
        -ms-animation-duration: 3s;
        -o-animation-duration: 3s;
        animation-duration: 3s;
    } @else if $name == flipInX {
        -webkit-animation-duration: 0.4s;
        -moz-animation-duration: 0.4s;
        -ms-animation-duration: 0.4s;
        -o-animation-duration: 0.4s;
        animation-duration: 0.4s;
    } @else if $name == wobbleLeft or $name == wobbleRight {
        animation-duration: 0.35s;
        animation-iteration-count: infinite;

        @if $name == wobbleLeft {
            transform-origin: 30% 10%;
        } @else {
            transform-origin: 20% 5%;
        }
    } @else {
        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -ms-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} { @content; }
    @-moz-keyframes #{$name} { @content; }
    @-ms-keyframes #{$name} { @content; }
    @-o-keyframes #{$name} { @content; }
    @keyframes #{$name} { @content; }
}

@mixin animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated {
    @include animated;
}

// fade
@include keyframes(fadeIn) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated.fadeIn {
    @include animation-name(fadeIn);
    @include animation-detail(fadeIn);
}

@include keyframes(fadeInDown) {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

.animated.fadeInDown {
    @include animation-name(fadeInDown);
    @include animation-detail(fadeInDown);
}

@include keyframes(fadeInLeft) {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.animated.fadeInLeft {
    @include animation-name(fadeInLeft);
    @include animation-detail(fadeInLeft);
}

@include keyframes(fadeInRight) {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.animated.fadeInRight {
    @include animation-name(fadeInRight);
    @include animation-detail(fadeInRight);
}

@include keyframes(fadeOut) {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.animated.fadeOut {
    @include animation-name(fadeOut);
    @include animation-detail(fadeOut);
}

@include keyframes(fadeOutUp) {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

.animated.fadeOutUp {
    @include animation-name(fadeOutUp);
    @include animation-detail(fadeOutUp);
}

// flip
@include keyframes(flipInX) {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }
    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }
    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

.animated.flipInX {
    @include animation-name(flipInX);
    @include animation-detail(flipInX);
}

// shine
@include keyframes(shine) {
    from {
        background-repeat: no-repeat;
        background-image: linear-gradient(
            to top left,
            rgba(181, 181, 181, 0) 0%,
            rgba(181, 181, 181, 0) 45%,
            rgba(181, 181, 181, 0.3) 48%,
            rgba(181, 181, 181, 0.5) 50%,
            rgba(181, 181, 181, 0.3) 52%,
            rgba(181, 181, 181, 0) 57%,
            rgba(181, 181, 181, 0) 100%
        );
        background-position: -250px -250px;
        background-size: 600px 600px;
    }
    to {
        background-repeat: no-repeat;
        background-position: 1000%;
    }
}

.animated.shine {
    @include animation-name(shine);
    @include animation-detail(shine);
}

// slide
@include keyframes(slideIn) {
  0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      -moz-transform: translateY(40px);
      -ms-transform: translateY(40px);
      -o-transform: translateY(40px);
      transform: translateY(40px);
  }
  30% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
  }
}

.animated.slideIn {
    @include animation-name(slideIn);
    @include animation-detail(slideIn);
}

// wobble
@include keyframes(wobbleLeft) {
    0% {
        transform: rotate(-1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(1.5deg);
        animation-timing-function: ease-out;
    }
}

.animated.wobble-left {
    @include animation-name(wobbleLeft);
    @include animation-detail(wobbleLeft);
}
@include keyframes(wobbleRight) {
    0% {
        transform: rotate(1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}

.animated.wobble-right {
    @include animation-name(wobbleRight);
    @include animation-detail(wobbleRight);
}

@import '../animation';
@import '../config';

$list: 250px;
$top: 106px;
$top-mobile: 149px;
$top-single-mobile: 211px;
$top-tablet: $top-mobile;

.search-input-box {
    position: relative;
    overflow: visible;

    .search-input {
        @include search-input;

        button {
            padding: 0;
            border: none;
            outline: 0;

            &:hover,
            &:focus {
                outline: none;
                text-decoration: none;
            }
            i {
                padding: 5px;
                font-size: 14px;
            }
            img {
                padding: 5px;
                width: 25px;
                height: 25px;
            }
        }
        > div > div > ul > li {
            padding-bottom: 10px 5px;
        }
        input.react-autosuggest__input {
            width: calc(#{$list} - 15px);
            padding: 8.2px 0; // height: 36px
            background-color: $colour-transparent;
            border: none;
            font-size: 14px;
            letter-spacing: 1px;
            outline: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -khtml-shadow: none;
            box-shadow: none;

            &--focused {
                outline: none;
            }
            &--open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .react-autosuggest__section-container {
            margin: 10px 0 0;
            border-top: $width-border solid $colour-grey;
            overflow-x: hidden;
            overflow-y: auto;

            &--first {
                border-top: none;
            }
        }
        .react-autosuggest__section-title {
            padding-left: 10px;
            color: $colour-dark-black;
        }
    }
}
.react-autosuggest__suggestions-list { // ul
    z-index: 1000;
    display: block;
    position: fixed;
    top: $top;
    width: $list;
    height: 200px;
    margin: 0;
    padding: 0;
    background-color: $colour-dark-white;
    border: $width-border solid $box-shadow-dark;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: $colour-dark-grey;
    font-size: 14.5px;
    font-weight: 300;
    overflow-x: hidden;
    overflow-y: auto;
    list-style-type: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
}
.react-autosuggest__suggestion {
    height: 50px;
    padding: 5px 10px;
    cursor: pointer;

    &.react-autosuggest__suggestion--isSelected {
        background-color: $colour-light-grey;
        color: $colour-dark-black;
    }
    &:hover {
        background-color: $colour-light-grey;
        color: $colour-dark-black;
    }
    &--highlighted {
        font-weight: 400;
    }
    &-match {
        color: $colour-orange;
        font-size: 16px;
        font-weight: 500;
        vertical-align: baseline;
    }
    > div {
        height: 20px;
    }
    .stock-name,
    .single-name {
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

// sorting
.dragging-helper-single {
    div {
        display: flex;
        padding: 10px;
        border: 2px solid $colour-grey;
        border-radius: 4px;
    }
}

body {
    &.Mobile {
        .search-input-box {
            .react-autosuggest__suggestions-list {
                top: $top-mobile;
                width: calc(100% - 60px);
            }
        }
    }
}

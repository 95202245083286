@import "../config";

$login-info: 80px;

.side-menu {
		z-index: 3;
		position: relative;
		top: 0;
		left: 0;
		width: $width-side-menu;
		height: 100%;
		padding-top: $height-toolbar;
		padding-left: 12px;
		padding-bottom: 15px;
		float: left;
		overflow: hidden;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-transition: margin-left .25s cubic-bezier(0.1, 0.57, 0.1, 1);
		-o-transition: margin-left .25s cubic-bezier(0.1, 0.57, 0.1, 1);
		-moz-transition: margin-left .25s cubic-bezier(0.1, 0.57, 0.1, 1);
		-ms-transition: margin-left .25s cubic-bezier(0.1, 0.57, 0.1, 1);
		transition: margin-left .25s cubic-bezier(0.1, 0.57, 0.1, 1);
		-webkit-transform: ranslate3d(0, 0, 0);
    -moz-transform: ranslate3d(0, 0, 0);
    -ms-transform: ranslate3d(0, 0, 0);
    -o-transform: ranslate3d(0, 0, 0);
    transform: ranslate3d(0, 0, 0);
		-ms-overflow-style: none;
		-webkit-overflow-scrolling: touch;

		.menu-item {
				position: relative;
				padding: 6px 10px 6px 0;
				-ms-overflow-style: none;
				-webkit-overflow-scrolling: touch;

				&.colour-theme {
						a {
								cursor: default;
						}
				}
				section,
				a {
						display: flex;
				    color: $colour-dark-grey;
				    font-size: 14px;
				    font-weight: 400;
						cursor: default;
						align-items: center;
				    line-height: normal;

						i {
								vertical-align: unset;
						}
				}
		}

		nav {
		    height: calc(100% - #{$login-info});
		    overflow-y: auto;

				.menu-list {
						padding-bottom: 32px;
            -ms-overflow-style: none;
						-webkit-overflow-scrolling: touch;

						a {
								&.open,
								&:hover,
								&:focus {
								    color: $colour-black;
								    font-weight: 500;
								}
								> span {
								    font-size: 14px;
								}
						}
						.menu-item {
								.collapse-sign {
										position: absolute;
										top: 8px;
										right: 10px;
								    left: 200px;
								    color: $colour-dark-grey;
								    font-size: 11px;
								    font-weight: normal;

										&:hover,
										&:focus {
										    color: $colour-dark-black;
										}
								}
								&-parent-title {
								    margin: 0 5px 1px 3px;
								    padding: 0;
								}
								&-parent {
				            -ms-overflow-style: none;
								    -webkit-overflow-scrolling: touch;
								}
								&-children {
										padding: 5px 15px;
				            -ms-overflow-style: none;
										-webkit-overflow-scrolling: touch;

										> div {
												color: $colour-dark-grey;
												font-size: 14px;
												font-weight: 400;

												> i {
														margin-right: 5px;
												}
										}
										&:hover,
										&:focus {
												span {
														color: $colour-light-orange;

														&::after {
																border-left-color: $colour-light-orange;
														}
												}
										}
										&.active,
										&:active {
												color: $colour-orange;

												&:hover,
												&:focus {
														span {
																color: $colour-light-orange;

																&::after {
																		border-left-color: $colour-light-orange;
																}
														}
												}
												> div {
														color: $colour-orange;
												}
												span::after {
														border-left-color: $colour-orange;
												}
										}
										> div {
												&:hover,
												&:focus {
														color: $colour-light-orange;
												}
												b {
												    position: absolute;
												    top: 10px;
												    right: 10px;
												}
										}
										&.is-editing {
												padding: 0;

												&:hover,
												&:focus,
												&.active,
												&:active {
														span {
																color: $colour-dark-grey;

																&::after {
																		border-left-color: $colour-transparent;
																}
														}
												}
												> div {
														display: flex;
														align-items: center;

														.menu-item-children-title {
																display: block;
																max-width: 170px;
																overflow: hidden;
																white-space: nowrap;
																text-overflow: ellipsis;
														}
												}
										}
										.mover {
												padding-top: 5px;
										}
								}
						}
				}
				.elegant {
				    margin-right: 1px;
				    margin-left: 2px;
				}
				.ua-triangle-after {
						display: flex;
						align-items: center;

						span::after {
						    content: "";
						    position: absolute;
						    width: 0;
						    height: 0;
						    margin-top: 4px;
						    margin-left: 4px;
						    border: 6px solid transparent;
						}
				}
		}
		// logged in
		.login-info {
				position: relative;
				height: $login-info;
				padding: 10px 0 20px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;

				* {
				    -webkit-box-sizing: border-box;
				    -moz-box-sizing: border-box;
				    box-sizing: border-box;
				}
				a {
				    display: block;
				    margin-top: 6px;
				    padding-right: 12px;
				    color: $colour-dark-grey !important;
						text-decoration: none;
				}
				span.user-info {
					max-width: 140px;
				    overflow: hidden;
				    vertical-align: middle;
				    white-space: nowrap;
				    text-overflow: ellipsis;
				    text-decoration: none;

						h4 {
						    font-weight: 400;
						}
						small span {
						    padding-left: 5px;
						    vertical-align: middle;
						    font-weight: 400;

								&:hover,
								&:focus,
								&.active {
								    font-weight: 500;
								}
						}
				}
				.frame {
				    width: 40px;
				    height: 40px;
				    margin: 0 10px 0 0;
				    background-position: center;
				    background-size: cover;
				    border: 2px solid $colour-grey;
				}
				.user-nickname {
						margin: 0;

						&.active {
						    font-weight: 500;
						}
				}
				.user-notice {
				    position: absolute;
				    top: 25px;
				    right: 20px;
				    width: 25px;
				    height: 25px;
				    padding: 0;
				    background-color: $colour-orange;
				    border: $width-border solid $colour-orange;
						-webkit-border-radius: $border-radius-circle;
						-moz-border-radius: $border-radius-circle;
						-khtml-border-radius: $border-radius-circle;
				    border-radius: $border-radius-circle;
				    color: $colour-white;
				    font-weight: 500;
				}
		}
}

.dragging-helper-menu {
		color: $colour-black;
		list-style-type: none;
}

// log in / out btn
.auth-btn-container {
		height: $height-toolbar;

		.auth-btn {
				display: block;
				width: 100%;
		}
}

.ua-app {
		&.menu-hidden {
				.side-menu {
				    margin-left: -#{$width-side-menu};
				}
		}
}

body {
		&.Mac {
				.side-menu nav {
				    height: calc(100% - #{$login-info});
				}
		}
		&.Mobile {
				.side-menu {
						position: absolute;
						-webkit-transition: none;
						-o-transition: none;
						-moz-transition: none;
						-ms-transition: none;
						transition: none;

						nav .menu-list .menu-item-parent .menu-item-children {
				        padding: 5px 27px;
				    }
				}
				.dragging-helper-menu {
						padding: 5px 27px;
				}
		}
}

@import "../config";

.dashboard-dropdown {
    position: relative;
    width: max-content;

    &.active {
        .dashboard-dropdown-menu-container {
            height: max-content;
            max-height: 550px;
            overflow-y: auto;
        }
    }
    &-btn-title {
        margin-right: 15px;
        font-weight: bolder;
    }

    // shape
    .ua-triangle {
        @include triangle;
    }

    &-menu-container {
        z-index: 1;
        position: absolute;
        right: -11px;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.3s ease;
        -moz-transition: max-height 0.3s ease;
        -ms-transition: max-height 0.3s ease;
        -o-transition: max-height 0.3s ease;
        transition: max-height 0.3s ease;
        width: max-content;
    }
    &-item {
        padding: 5px 11px;
    }
    .search-box {
        position: relative;
        padding-top: 10rem;
        width: 100%;

        .search-box__text {
            color: $colour-orange;
        }
    }
}

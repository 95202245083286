@import "../config";

.pagination {
    display: flex !important;
    margin: 12px 0 0;
    border-width: $width-border;
    border-style: solid;
    -webkit-border-radius: $border-radius-btn;
    -moz-border-radius: $border-radius-btn;
    -khtml-border-radius: $border-radius-btn;
    border-radius: $border-radius-btn;
    float: right;
    font-size: 12px;

    &-item {
        display: flex;
        cursor: pointer;
        align-items: center;

        .ua-txt-btn {
            position: relative;
            margin-left: -1px;
            padding: 6px 8.8px;
            background-color: $colour-transparent;
            -webkit-border-radius: unset;
            -moz-border-radius: unset;
            -khtml-border-radius: unset;
            float: left;
            text-decoration: none;
        }
        &:first-child {
            .ua-txt-btn {
                border-top-left-radius: $border-radius-btn;
                border-bottom-left-radius: $border-radius-btn;
            }
        }
        &:last-child {
            .ua-txt-btn {
                border-top-right-radius: $border-radius-btn;
                border-bottom-right-radius: $border-radius-btn;
            }
        }
        &.active {
            .ua-txt-btn {
                border-color: $colour-orange;
                background-color: $colour-orange;
            }
            &:hover,
            &:focus {
                border-color: $colour-light-orange;
                background-color: $colour-light-orange;
            }
        }
        &.disabled {
            background-color: $colour-grey;

            .ua-txt-btn {
                cursor: no-drop;

                &:hover,
                &:focus {
                    cursor: no-drop;
                }
            }
        }
    }
}

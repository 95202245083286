@import "../config";

$box-shadow: rgba(181, 181, 181, 0.9);

#ua-root {
    position: fixed;
  	width: 100%;
  	height: 100%;
  	margin: 0;
  	padding: 0;
}
.ua-app {
    position: relative;
    margin: 0;
    padding: 0;

    // cookie
    .cookieConsent {
        width: auto !important;
        left: unset !important;
    }
    .fixed-bar {
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100vw - #{$width-side-menu});
        margin-left: $width-side-menu;

        > * {
            background-color: inherit;
        }
    }
    .toolbar {
        height: $height-toolbar;

        > * {
            overflow: hidden;
            line-height: $height-toolbar;
        }
    }
    &.menu-hidden {
        .fixed-bar {
            width: 100vw;
            margin-left: 0;
        }
        .toolbar {
            width: 100vw;
        }
    }
}
.ua-body {
    z-index: 1;
    position: relative;
    overflow: auto;
}
.ua-page {
    z-index: 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.ua-content {
    min-height: 100%;
    margin-bottom: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;

    > * {
        &:not(.ua-dashboard):not(.product-list) {
            padding-top: calc(#{$height-toolbar} + 15px);
        }
    }
}

// page-loading
.ua-before-loading {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../../img/loading/logo.png);
    background-repeat: no-repeat;
    background-size: 100px;
    background-color: $colour-transparent;
    background-position: 50% 20%;
}

// under-construction
.under-construction {
    width: 100vw;
    height: 100vh;

    > div {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    h1 {
        font-size: 5em;
        font-weight: 700;
    }
    i {
        font-size: 20em;
    }
}

// scrollbar
::-webkit-scrollbar {
    width: 4px;

    &-thumb {
        background-color: $colour-grey;
        border: $width-border solid $box-shadow-light;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -khtml-border-radius: 10px;
        border-radius: 10px;
    }
}

body {
    &.Mobile {
        .under-construction {
            h1 {
                font-size: 2.5em;
            }
            i {
                font-size: 10em;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .ua-app {
        .ua-body {
            padding-top: 0;
        }
        .cookieConsent .cookie-flex {
            flex: auto !important;
            text-align: justify;
        }
        .ua-content {
            margin-bottom: 15px;

            > * {
                &:not(.ua-dashboard):not(.product-list) {
                    padding-top: $height-toolbar;
                }
            }
        }
        .fixed-bar {
            width: 100%;
            margin-left: 0;
        }
        .toolbar {
              > * {
                 overflow: hidden;
              }
        }
    }

    .ua-loading {
        background-size: auto 50%;
    }
}

@import "../config";

$top: calc(#{$height-toolbar} + 10px);
$margin-top: 10vh;

.modal {
    &.show {
        display: block;
        opacity: 1;

        .modal-dialog {
            z-index: 4;
            height: 100vh;
            margin-top: 10vh;
        }
    }
    &.mobile-height {
        .modal-body {
            height: 45vh;
        }
    }
    &.full-height {
        .modal-dialog {
            max-width: 1000px;
        }
    }
    &.full-page {
        .modal-dialog {
            max-width: 1000px;

            .modal-content {
                width: 60vw;
                height: calc(100vh - #{$top} * 2);
                margin: auto;
            }
        }
    }
    > section.ua-page {
        overflow: hidden;
    }
    &-content {
        border-width: $width-border;
        border-style: solid;
        -webkit-border-radius: $border-radius-box;
        -moz-border-radius: $border-radius-box;
        -khtml-border-radius: $border-radius-box;
        border-radius: $border-radius-box;

        .modal-header {
            .close {
                font-size: 20px;
                opacity: 1;
                text-shadow: none;

                &:hover,
                &:focus,
                &:active {
                    opacity: 1 !important;
                    outline: none;
                }
            }

            .modal-title {
                margin: 0;
                white-space: initial;
            }
        }
        .modal-body {
            height: calc(100vh - 300px);
            font-size: 14px;
            text-align: justify;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;
            -webkit-overflow-scrolling: touch;

            img:not(.preview-img) {
                width: 100% !important;
                height: 100% !important;
            }
            .ua-form fieldset {
                overflow-y: auto;
            }
            .checkout-completed .redirect .cover-on-top-right {
                position: relative;
                top: -20px;
                padding-right: 25px;
                text-align: right;
            }
        }
        .modal-footer {
            padding-top: 0;
            padding-bottom: 0;
            border: none;

            .botton-action button {
                margin-left: 15px;
                padding: 6.5px 12px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

body {
    &.modal-open {
        padding: 0;

        .modal {
            overflow-y: hidden;
        }
    }
    &.Mobile {
        .modal {
            &.full-height,
            &.full-page {
                .modal-dialog {
                    margin: 0;

                    .modal-content {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        border: none;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        -khtml-border-radius: 0;
                        border-radius: 0;

                        .modal-header {
                            // z-index: 3;
                            position: relative;
                            border-bottom: none;

                            h4 {
                                width: 100%;
                                font-weight: 500;
                                text-align: center;
                                line-height: initial;
                            }
                        }
                        .modal-body {
                            padding: 0 0 50px;
                            overflow: scroll;

                            .ReactTable {
                                padding: 0;
                            }
                        }
                    }
                }
            }
            &-content {
                .modal-header .modal-title {
                    margin-left: 25px;
                }
            }
        }
    }
}

@media only screen and (min-width: 521px) and (max-width: 1024px) {
    .ua-app {
        &.menu-hidden {
            .modal .modal-dialog .modal-content {
                margin-left: auto;
            }
        }
        &:not(.menu-hidden) {
            .modal {
                .modal-dialog {
                    margin-left: 0;

                    .modal-content {
                        margin-left: calc((100vw - #{$width-side-menu}) / 2);
                    }
                }
            }
        }
        .modal {
            &.full-page {
                .modal-dialog {
                    .modal-content {
                        height: 50vh;
                        margin-top: 10vh;
                    }
                }
            }
            .modal-dialog {
                margin-top: calc(#{$height-toolbar} + 60px);
            }
        }
    }
}

@import "../config";

$font-weight: 900;

.ua-table {
    display: block;
    width: 100%;
    padding: 0 0 $space;
    list-style: none;

    tbody {
        display: table;
        width: 100%;
    }
    a {
        &.ua-btn {
            vertical-align: middle;
        }
    }
    &-row {
        border-bottom: $width-border solid $colour-grey;

        &:last-child {
            border-bottom: none;

            .ua-table-cell {
                border-bottom: none;
            }
        }
        .ua-table-cell {
            padding: 6px;

            &-btn {
              color: $colour-orange;
              vertical-align: bottom;
          }
        }
    }
    &-cell {
        border-bottom: $width-border solid $colour-grey;
    }
    &-title {
        > .ua-table-cell:first-child,
        > th {
            width: 25%;
            font-weight: 400;
            text-align: center;
        }
        .ua-table-info {
            width: 15%;
        }
    }
    &-btn {
        &:hover,
        &:focus {
            color: $colour-light-orange;

            i {
                color: $colour-light-orange;
            }
        }
        &:active {
            color: $colour-orange;

            i {
                color: $colour-orange;
            }
        }
        i {
            color: $colour-orange;
        }
    }
    &-cell-btn {
        cursor: pointer;
        vertical-align: bottom;
        text-align: center;
    }
    .ua-btn {
        border: none;

        &:hover,
        &:focus,
        &:active {
            border: none;
        }
        > i {
            font-size: 20px;
        }
    }
}

// data table single
// 1. div
// @mixin
@mixin animation {
    -webkit-transition: 0.6s ease-in;
    -moz-transition: 0.6s ease-in;
    -ms-transition: 0.6s ease-in;
    -o-transition: 0.6s ease-in;
    transition: 0.6s ease-in;
}

@mixin column {
    display: flex;
    border: $width-border solid $table-border;
    border-left: none;
    border-right: none;
    align-items: center;
    text-overflow: clip;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-shadow: none;
    box-shadow: none;
    white-space: initial;
}

@mixin sort {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-shadow: none;
    box-shadow: none;

    .data-table-header {
        &.sort {
            @include data-table-header;
            white-space: nowrap;
        }
    }
}

@mixin data-table-header {
    color: $colour-orange;
    font-weight: 500;

    &::after {
      content: "\f0dc";
      margin-left: 2px;
      font-family: $font-family-awesome;
      font-weight: $font-weight;
    }
}

@mixin column-desktop {
    height: 45px;
    text-overflow: clip;

    &:first-child {
        z-index: 1;
        position: sticky;
        left: 0;
    }
}

.table-component {
    &.ReactTable {
        padding-left: $space;
        padding-right: $space;
        border: none;
        font-size: 13px;

        .rt-table {
            &::-webkit-scrollbar {
                height: 4px;
            }
            .rt-thead {
                overflow: visible;
                display: inline-grid;
                z-index: 2;
                position: sticky;
                top: 0;
                .rt-tr{
                    >div:not(:last-child){
                        border-right: $width-border solid $table-border;
                    }
                }
                .rt-th {
                    @include animation;
                    @include column;
                    @include column-desktop;
                    justify-content: center;
                    &.-cursor-pointer {
                        &:hover,
                        &:focus {
                            outline: none;
                        }
                        .data-table-header {
                            @include data-table-header;

                            &.sort {
                                white-space: nowrap;
                                &:hover,
                                &:focus {
                                    color: $colour-light-orange;
                                }
                            }
                        }
                    }
                    &.-sort-asc {
                        @include sort;

                        .data-table-header {
                            &.sort {
                                white-space: nowrap;
                                &::after {
                                    content: "\f0de";
                                    vertical-align: -2px;
                                }
                            }
                        }
                    }
                    &.-sort-desc {
                        @include sort;

                        .data-table-header {
                            &.sort {
                                white-space: nowrap;
                                &::after {
                                    content: "\f0dd";
                                    vertical-align: 2px;
                                }
                            }
                        }
                    }
                }
            }
            .rt-tbody {
                &::-webkit-scrollbar {
                    height: 4px;
                }
                overflow: visible;
                display: inline-grid;
                position: relative;

                .rt-tr-group {
                    border: none;
                    .rt-tr{
                        >div:not(:last-child){
                            border-right: $width-border solid $table-border;
                        }
                    }

                    .rt-td {
                        @include animation;
                        @include column;
                        @include column-desktop;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        font-weight: 400;
                        text-align: center;

                        &:first-child {
                            text-align: left;
                            justify-content: unset;
                        }
                        &:nth-child(n) {
                            padding: 4px;
                        }
                        > div {
                            width: 100%;
                            overflow: hidden;

                            &.rankings-title-first {
                                display: flex;
                                align-items: center;
                                white-space: nowrap;

                                > * {
                                    display: flex;
                                    align-items: center;
                                }
                                &:not(.txt-bold) {
                                    font-weight: 400;
                                }
                                .show-more-icon {
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 2. table
table.table-component {
    padding: $space;
    background-color: $colour-transparent;
    border-collapse: collapse;
    font-size: 13px;

    tbody {
        background-color: $colour-transparent;
        border: $width-border solid $colour-grey;
    }
    tr {
        background-color: $colour-transparent;
        border-top: $width-border solid $colour-grey;
    }
    th {
        padding: 6px;
        background-color: $colour-transparent;
        border-left: $width-border solid $colour-grey;
        border-right: $width-border solid $colour-grey;
    }
    td {
        padding: 6px;
        background-color: $colour-transparent;
        border-right: $width-border solid $colour-grey;
    }
}

@media only screen and (max-width: 520px) {

}

body.Mobile .ua-app,
body.Mobile .ua-app.menu-hidden {
    .component .component-content {
        .table-component {
            &.ReactTable {
                .rt-table{
                    width: 100%;
                }
                .rt-thead {
                    .rt-th {
                        span {
                            white-space: normal;
                        }
                    }
                }
                .rt-tbody {
                    .rt-tr-group {
                        .rt-td {
                            border-bottom: none !important;
                        }
                    }
                }
            }
        }

        // modal
        .modal {
            &.table-modal {
                .modal-content .modal-body {
                    .table-modal-toolbar {
                        overflow: visible;

                        .search-input-box .search-input .react-autosuggest__suggestions-list {
                            top: calc(#{$height-input} - 1px);
                            width: calc(100% - 30px);
                        }
                    }
                    .pagination {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

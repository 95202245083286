@import './animation';

// HEIGHT
// input
$height-input: 36px;

// WIDTH
// border
$width-border: 1px;

// MARGIN / PADDING
$space: 15px;

// LAYOUT
$width-side-menu: 240px;
$height-toolbar: 60px;
$line-height: 1.4;

// RADIUS
$border-radius-ai-box: 10px;
$border-radius-box: 4px;
$border-radius-btn: 3px;
$border-radius-circle: 50%;
$border-radius-input: 170.5px;

// SCALE
$scale: 1.05;
$scale-product: 1.02;
$scale-selection: 1.07;

// COLOUR
$colour-dark-black: #000;
$colour-black: #393939;
$colour-blue: #0084ff;
$colour-dark-blue: #1455c2;
$colour-light-blue: #73a2ff;
$colour-green: #4abf70;
$colour-dark-grey: #545454;
$colour-grey: #b5b5b5;
$colour-light-grey: #e1e1e1;
$colour-magenta: #ef3474;
$colour-light-magenta: #ff528d;
$colour-orange: #ffa500;
$colour-fade-orange: rgba(255, 165, 0, 0.85);
$colour-light-orange: #ffc04d;
$colour-red: #ef5350;
$colour-white: #fff;
$colour-dark-white: #f7f7f7;
$colour-transparent: rgba(0, 0, 0, 0);
$colour-turquoise: #40e0d0;

// border
$border-dark: rgba(0, 0, 0, 0.2);
$border-light: rgba(255, 255, 255, 0.2);

// gradient
$colour-gradient-dark-from: #222;
$colour-gradient-dark-to: #666;
$colour-gradient-light-from: #fdfdfd;
$colour-gradient-light-to: #ccc;

// $nearly b & w
$nearly-black: rgba(0, 0, 0, 0.9);
$nearly-white: rgba(255, 255, 255, 0.9);

// shadow
$box-shadow-dark: rgba(0, 0, 0, 0.1);
$box-shadow-light: rgba(255, 255, 255, 0.1);
$txt-shadow-dark: 0 0 0 $nearly-black;
$txt-shadow-light: 0 0 0 $nearly-white;

// table
$table-border: rgba(181, 181, 181, 0.2);
$table-bg-even: rgba(181, 181, 181, 0.12);
$table-bg-dark-even: #616161;
$table-bg-light-even: #efefef;

// FONT
$font-family: Source Han Sans TC, source-han-sans-tc, Noto Sans TC, Open Sans, sans-serif;
$font-family-awesome:"Font Awesome 5 Pro";
$font-size: 14.5px;

// @MIXIN
@mixin input {
    position: relative;
    width: 100%;
    background-color: $colour-transparent;
    background-image: none;
    border: $width-border solid $colour-grey;
    -webkit-border-radius: $border-radius-box;
    -moz-border-radius: $border-radius-box;
    -khtml-border-radius: $border-radius-box;
    border-radius: $border-radius-box;
    font-weight: 300;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-shadow: none;
    box-shadow: none;
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial;
}
@mixin highlight {
    border: $width-border solid rgba(255, 165, 0, 0.5);
    -webkit-box-shadow: 0 1px 1px 0 rgba(255, 165, 0, 0.2);
    -moz-box-shadow: 0 1px 1px 0 rgba(255, 165, 0, 0.2);
    -khtml-shadow: 0 1px 1px 0 rgba(255, 165, 0, 0.2);
    box-shadow: 0 1px 1px 0 rgba(255, 165, 0, 0.2);
}
@mixin triangle {
    top: 4px;
    right: 0;

    &.active {
        top: 9px;
    }
}
@mixin ua-box {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 5px;
    border-width: 1px;
    border-style: solid;
    -webkit-border-radius: $border-radius-box;
    -moz-border-radius: $border-radius-box;
    -khtml-border-radius: $border-radius-box;
    border-radius: $border-radius-box;
    float: left;
    text-align: left;
}
@mixin search-input {
    @include animation-name(fadeInRight);
    display: flex;
    position: relative;
    height: $height-input;
    padding-left: 15px;
    padding-right: 10px;
    background-color: $colour-transparent;
    -webkit-border-radius: $border-radius-input;
    -moz-border-radius: $border-radius-input;
    -khtml-border-radius: $border-radius-input;
    border-radius: $border-radius-input;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-shadow: none;
    box-shadow: none;
    align-items: center;
}

@import "../config";

@mixin animation {
    -webkit-transition: 0.6s ease-in;
    -moz-transition: 0.6s ease-in;
    -ms-transition: 0.6s ease-in;
    -o-transition: 0.6s ease-in;
    transition: 0.6s ease-in;
}
@mixin column {
    border: $width-border solid $table-border;
    border-left: none;
    border-right: none;
    align-items: center;
    text-overflow: clip;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -khtml-shadow: none;
    box-shadow: none;
    white-space: initial;
}
@mixin data-table-header {
    color: $colour-orange;
    font-weight: 500;
}

.component {
    .ai {
        width: 100%;

        .module-left {
            // width: 100%;
            width: 25%;
            padding: 0 20px 0 0;
            // overflow: hidden auto;
            overflow: hidden;

            .account-group {
                .account-btn.sub-account {
                    display: block;
                }
            }
        }
        .search-input-box {
            margin-left: 0;

            .search-input {
                width: 100%;
                border: none;

                input.react-autosuggest__input {
                    width: 100%;
                }
                .react-autosuggest__container {
                    width: 100%;
                }
            }
        }
        .ai-list {
            // width: 25%;
            border-radius: $border-radius-ai-box;
            margin-top: 10px;
            overflow: hidden auto;
            padding: 5px 15px 20px;
        }
        // .module-right {
            em {
                &::before {
                  vertical-align: baseline;
                }
            }
            h1 {
                font-size: 19px;
            }
            h2 {
                font-size: 17px;
            }
            h3 {
                font-size: 16px;
                // margin-bottom: 10px;
            }
            h4:not(.modal-title),
            h5,
            h6 {
                font-size: 15px;
                margin: 30px 0 20px;
            }
            li {
                line-height: 2;
                margin-bottom: 8px;
            }
            p {
                line-height: 2.5;
                font-size: 15px;
                margin: 10px 0 30px;
            }
            ul,
            ol {
                padding-left: 12px;
                list-style-type: none;
                border-left: 2px solid $colour-orange;
                margin: 10px 0 20px;
            }
            ol {
                line-height: 2;
            }
            table {
                background-color: $colour-transparent;
                border-collapse: collapse;
                width: 100%;
                overflow: hidden;
                padding: $space;
                // padding-left: $space;
                // padding-right: $space;
                border: none;
                font-size: 13px;
                margin: 40px 0;

                &::-webkit-scrollbar {
                    height: 4px;
                }
                tbody {
                    background-color: $colour-transparent;
                    overflow: visible;

                    &::-webkit-scrollbar {
                        height: 4px;
                    }
                    td {
                        padding: 6px;
                        background-color: $colour-transparent;
                        border-right: $width-border solid $colour-grey;
                    }
                    th {
                        padding: 6px;
                        background-color: $colour-transparent;
                        border-left: $width-border solid $colour-grey;
                        border-right: $width-border solid $colour-grey;
                    }
                    tr {
                        background-color: $colour-transparent;

                        > td:not(:last-child) {
                            border-right: $width-border solid $table-border;
                        }
                        td {
                            @include animation;
                            @include column;
                            border-top: none;
                            border-left: none;
                            // border-right: none;
                            font-weight: 400;
                            text-align: center;
                            line-height: 2.4;

                            &:nth-child(n) {
                                padding: 4px;
                            }
                            > div {
                                width: 100%;
                                overflow: hidden;
                            }
                        }
                    }
                }
                thead {
                    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);

                    tr {
                        > th:not(:last-child) {
                            border-right: $width-border solid $table-border;
                        }
                        th {
                            @include animation;
                            @include column;
                            text-align: center;
                            line-height: 2.4;
                            flex: 100 0 auto;
                            @include data-table-header;
                        }
                    }
                }
            }
            .hr {
                border-top: 2px solid $colour-orange;
                padding-top: 20px;
                margin-top: 50px;
            }
            // table {
            //     padding: $space;
            //     background-color: $colour-transparent;
            //     border-collapse: collapse;
            //     font-size: 13px;
            //
            //     tbody {
            //         background-color: $colour-transparent;
            //     }
            //     tr {
            //         background-color: $colour-transparent;
            //     }
            //     th {
            //         padding: 6px;
            //         background-color: $colour-transparent;
            //         border-left: $width-border solid $colour-grey;
            //         border-right: $width-border solid $colour-grey;
            //     }
            //     td {
            //         padding: 6px;
            //         background-color: $colour-transparent;
            //         border-right: $width-border solid $colour-grey;
            //     }
            // }
            .updated-time {
                width: 100;
                margin: 5px 0 20px;
                text-align: right;
            }
            .module-tool {
                margin: 10px 0;
            }
        // }
        .question-inputs {
            .inputs {
                button {
                    margin: 0;
                }
                input {
                    width: calc(100% - 60px);
                    margin-right: auto;
                }
            }
        }
        .keyword-highlight {
            background-color: $colour-dark-blue;
            color: $nearly-white;
            font-weight: normal;
        }
    }
}

body.Mobile {
    .ai-list, .ai-report .ai-report-list.module-left {
        padding: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .ai-list, .ai-report .ai-report-list.module-left {
        width: 100%;
        padding: 0 10px;
    }
}

@import "./config";

body {
    /* DARK */
    &.dark {
        /* For browsers that do not support gradients */
        background-color: $colour-black;
        /* For Safari 5.1 to 6.0 */
        background-color: -webkit-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
        background-color: -webkit-gradient(linear, left top, left bottom, from($colour-gradient-dark-from), to($colour-gradient-dark-to));
        /* For Firefox 3.6 to 15 */
        background-color: -moz-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
        /* For Opera 11.1 to 12.0 */
        background-color: -o-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
        /* Standard syntax */
        background-color: linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
        color: $nearly-white;
        font-weight: 300;

        /* ELEMENT */
        nav ul li a {
            color: $colour-dark-grey;
        }

        /* input */
        .ua-input {
            color: $colour-white;
        }

        /* btn */
        .ua-btn {
            &.default {
                &.contrast {
                    &:not(.disabled) {
                        background-color: $colour-light-grey;
                        border-color: $colour-light-grey;
                        color: $colour-black;
                    }
                    &:active,
                    &:hover,
                    &:focus {
                        background-color: $colour-white;
                        border-color: $colour-white;
                        color: $colour-dark-black;
                    }
                }
            }
            &.outline {
                &.text {
                    border-color: $colour-light-grey;
                    color: $colour-light-grey;

                    &:active,
                    &:hover,
                    &:focus {
                        border-color: $colour-white;
                        color: $colour-white;
                    }
                }
                &.contrast {
                    &:not(.disabled) {
                        border-color: $colour-light-grey;
                        color: $colour-light-grey;
                    }
                    &:active,
                    &:hover,
                    &:focus {
                        border-color: $colour-white;
                        color: $colour-white;
                    }
                }
            }
        }

        /* input */
        input {
            color: $colour-white;
        }
        select {
            background-color: $colour-dark-grey;
            text-shadow: $txt-shadow-light;

            option {
                background-color: $colour-black;
                color: $colour-white;
            }
        }

        /* tab */
        .ua-tab {
            li {
                span {
                    color: $nearly-white;
                }
                &.active {
                    span {
                        color: $colour-white;

                        &:hover,
                        &:focus {
                            color: $colour-white;
                        }
                    }
                }
            }
        }
        .ua-btn-tab {
            border-color: $colour-black;
            color: rgba(255, 255, 255, 0.5);

            &.active,
            &:hover,
            &:focus {
                border-color: $colour-black;
                color: $nearly-white;
            }
        }
        .tab-content {
            background-color: $colour-dark-grey;
        }
        .ua-vertical-tab {
            li {
                .ua-tab-title {
                    background-color: $colour-black;
                    border-color: $colour-dark-grey;
                    color: $nearly-white;
                }
                &:hover,
                &:focus,
                &.active {
                    .ua-tab-title {
                        background-color: $colour-dark-grey;
                        color: $colour-white;
                    }
                }
                &.active {
                    .ua-tab-title {
                        background-color: $colour-dark-grey;
                    }
                }
            }
            &-pane  {
                background-color: $colour-dark-grey;
            }
            &-content {
                background-color: $colour-black;
            }
        }
        .ua-horizontal-tab {
            .ua-tab-item {
                &:hover:not(.add-item-btn),
                &:focus:not(.add-item-btn) {
                    > span {
                        background-color: rgba(84, 84, 84, 0.7);
                        border-color: rgba(84, 84, 84, 0.7);
                    }
                }
                &.active {
                    &:hover,
                    &:focus {
                        > span {
                            background-color: $colour-dark-grey;
                            border-color: $colour-dark-grey;
                            color: $colour-white;
                        }
                    }
                    > span {
                        background-color: $colour-dark-grey;
                        border-color: $colour-dark-grey;
                    }
                }
                > span {
                    background-color: $colour-black;
                    border-color: $colour-dark-grey;
                    color: $nearly-white;
                }
            }
            &-content {
                background-color: $colour-black;
            }
            &-pane  {
                background-color: $colour-dark-grey;
            }
        }
        /* box */
        .ua-box {
            &.default:not(.ua-tooltip) {
                background-color: $colour-dark-grey;
                border-right-width: 0;
                border-bottom-width: 0;
            }
            &:not(.outline) {
                border-color: $border-light;
                -webkit-box-shadow: 1px 1px 2px 1px $box-shadow-light;
                -moz-box-shadow: 1px 1px 2px 1px $box-shadow-light;
                -khtml-shadow: 1px 1px 2px 1px $box-shadow-light;
                box-shadow: 1px 1px 2px 1px $box-shadow-light;
            }
            &.outline {
                border-color: $colour-grey;
            }
            &.ua-tooltip {
                border-right-width: 1px;
                border-bottom-width: 1px;
                -webkit-box-shadow: 1px 1px 2px 1px $box-shadow-dark;
                -moz-box-shadow: 1px 1px 2px 1px $box-shadow-dark;
                -khtml-shadow: 1px 1px 2px 1px $box-shadow-dark;
                box-shadow: 1px 1px 2px 1px $box-shadow-dark;
            }
            &.portfolio-value-settings {
                background-color: $colour-black;
                -webkit-box-shadow: 2px 2px 4px 2px $box-shadow-light;
                -moz-box-shadow: 2px 2px 4px 2px $box-shadow-light;
                -khtml-shadow: 2px 2px 4px 2px $box-shadow-light;
                box-shadow: 2px 2px 4px 2px $box-shadow-light;
            }
        }

        /* progress */
        .ua-progress {
            background-color: $nearly-white;
            border: none;
        }

        /* table */
        .data-table .fullscreen,
        .filter-table.fullscreen {
            background-color: $colour-black;
        }
        .ReactTable {
            .rt-thead {
                .rt-th:first-child,
                .rt-td:first-child,
                .rt-th,
                .rt-td {
                    background-color: $colour-dark-grey;
                }
            }
            .rt-tbody {
                .rt-tr-group {
                    border-bottom: $width-border solid $border-light;
                }
                .rt-td:first-child,
                .rt-td {
                    background-color: $colour-dark-grey;
                }
            }
        }
        .ai-report,
        .article-view,
        .ua-box .ReactTable {
            tbody {
                tr {
                    &:nth-child(even) {
                        td {
                            background-color: $table-bg-dark-even;
                        }
                    }
                }
            }
            .rt-tbody {
                .rt-tr-group {
                    &:nth-child(even) {
                        .rt-td {
                            background-color: $table-bg-dark-even;
                        }
                    }
                }
            }
        }

        /* pagination */
        .pagination {
            border-color: $border-light;
            color: $colour-white;

            > li {
                > a,
                > span {
                    color: $colour-white;
                }
                &.disabled {
                    > a,
                    > span {
                        color: $colour-white;
                    }
                }
            }
        }

        /* modal */
        .modal {
            color: $colour-light-grey;

            .modal-dialog .modal-content {
                background-color: $colour-dark-grey;
                border-color: $border-light;
                -webkit-box-shadow: 3px 0 3px 0 $box-shadow-light;
                -moz-box-shadow: 3px 0 3px 0 $box-shadow-light;
                -khtml-shadow: 3px 0 3px 0 $box-shadow-light;
                box-shadow: 3px 0 3px 0 $box-shadow-light;

                .modal-header {
                    border-bottom-color: $box-shadow-light;

                    .close {
                        color: $colour-light-grey;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $colour-white;
                        }
                    }
                }
            }
            &.range-switch-modal {
                .modal-dialog .modal-content .switch-type {
                    border-color: $colour-light-grey;
                }
            }
        }

        .ua-backdrop {
            background-color: rgba(0, 0, 0, 0.6);

            &.tooltip-backdrop {
                background-color: $colour-transparent;
            }
        }

        /* LAYOUT */
        /* page */
        .fixed-bar {
            background-color: $colour-black;
        }
        .white-btn .menu-toggler {
            color: $colour-grey;
        }
        .logged-in-header.white-btn .menu-toggler {
            z-index: 2;
            background-color: $colour-transparent;
            color: $colour-white;
            text-align: center;
        }
        &.Mobile {
            .ua-app.menu-hidden .menu-toggler {
                color: $colour-white;
            }
        }

        /* toolbar */
        .toolbar {
            border-bottom: $width-border solid $colour-dark-grey;
        }

        /* e-com */
        .ua-box {
            a {
                color: $colour-light-grey;

                &.outline-orange,
                &.txt-orange {
                    color: $colour-orange;
                }
            }
            .product-name {
                color: $colour-white;
                font-weight: 500;
            }
        }

        /* auth */
        .auth-content-title {
            color: $colour-light-grey;

            h2 {
                color: $colour-white;
            }
        }

        /* profile */
        .mobile-title {
            color: $colour-white;
        }

        /* my-lab */
        .lab-list .lab-setting {
            a {
                color: $colour-light-grey;

                &:hover,
                &:focus,
                &:active {
                    color: $colour-white;
                }
            }
        }
        .lab-setting-page {
            .lab-setting-option .add-lab .products-selection .products-selection-header {
                background-color: #171717;
            }
        }

        /* dashboard */
        .stock-container,
        .article-list {
            &-search,
            &-pagination {
                background-color: $colour-black;
            }
            .article-container-wrap {
                &.top-article {
                    background-color: #6a4c48;

                    .article-date {
                        color: $colour-dark-grey;
                    }
                }
                .article-date {
                    color: $colour-grey;
                }
            }
            .search-input {
                background-color: $colour-dark-grey;
            }
        }
        .single-switch-tab,
        .single-switch-content {
            background-color: $colour-black;
        }
        .search-input {
            button,
            > div > input {
                color: $colour-white;
            }

            input::placeholder,
            input:-ms-input-placeholder,
            input::-ms-input-placeholder {
                color: $colour-light-grey;
            }
        }
        .filter-btn {
            color: $colour-white;
        }
        .summary-table-text {
            color: #d3d3d3;
        }
        .dashboard-dropdown {
            &-menu {
                background-color: $colour-black;

                &-container .ua-box {
                    background-color: $colour-black;

                    .dashboard-dropdown-labBtn .lab-btn-wrap {
                        background-color: $colour-black;
                        background-color: #2C2C2C;
                    }
                }
                &-item {
                    .dropdown-item {
                        border-color: $colour-grey;
                        color: $colour-grey;
                    }
                }
            }
            &-item {
                .dropdown-item {
                    border-color: $colour-dark-grey;
                    color: $colour-light-grey;
                }
            }
            .ua-triangle {
                border-top-color: $nearly-white;
            }
        }
        .ua-dashboard .lab-btn-modal-wrap .lab-btn-modal-contents,
        .ai-single .search-input-box .search-input,
        .ai-list,
        .ua-dashboard .ai .module-right .answer,
        .ua-dashboard .ai .module-right .question-input {
            background-color: rgba($colour-white, 0.06);
        }
        .ua-dashboard{
            .shortcutKey{
                .shortcutKey-hint{
                    background-color: $colour-dark-grey;
                    border: 1px solid $border-light;
                }
            }
        }
        &.Mobile {
            .module-left .content-btn {
                &:hover {
                    color: $colour-grey;
                }
            }
        }
        .module-right {
            .conclusion-wrap--sm {
                background-color: rgba($colour-white, 0.06);
            }
            .module-right-header {
                background-color: $colour-dark-grey;

                i:hover {
                    background-color: rgba($colour-white, 0.2);
                }
            }
            .conclusion {
                h1,
                h2,
                h3,
                h4,
                h5,
                p strong,
                li strong {
                    color: $colour-dark-white;
                    font-weight: 500;
                }
                p,
                li {
                    color: $colour-light-grey;
                }
            }
        }
        .content-group {
            .content-title {
                font-weight: 400;
                color: $colour-dark-white;
            }
            .content-btn {
                color: $colour-grey;
            }
        }
        .ai-report {
            .summary-table-text {
                font-weight: 400;
                color: $colour-grey;
            }
        }
        .ua-highcharts {
            &-note {
                &__title {
                    color: #d3d3d3;
                }
            }
        }
        .solidgauge-tooltip {
            color: $colour-light-grey;
        }
        .news-title {
            .news-date {
                color: $colour-light-grey;
            }
        }
        .account-btn {
            &.sub-account {
                color: $colour-light-grey;
            }
        }
        .chart-function-list {
            .chart-function-item {
                &-name {
                   &.function-item {
                       color: $colour-light-blue;
                   }
                }
                &-value {
                    &.chart-function-select,
                    &.chart-function-input {
                        border-color: $colour-light-grey;
                    }
                }
            }
        }
        .column {
            .keyword {
                background-color: $colour-dark-grey;
            }
            .article-bottom .detail-btn-mobile {
                color: $colour-black;
            }
        }
        .stock-box {
            background-color: $colour-light-grey;
            color: $nearly-black;
        }
        &.Mobile {
            a.detail-btn-mobile {
                color: $colour-white;
            }
            .company-info-mobile {
                .stock-name,
                .small-table-row {
                    border-bottom-color: $colour-light-grey;
                }
            }
        }

        /* playground */
        .result-exist-list {
            .sort-box {
                border-color: $colour-white;
                color: $colour-white;
            }
        }

        /* footer */
        .page-footer {
            border-top: $width-border solid $colour-light-grey;
        }
        .ua-body {
            .ua-page {
                .page-footer {
                    color: $colour-white;

                    a {
                       color: $colour-white;
                   }
                }
            }
        }
    }

    /* LIGHT */
    &.light {
        /* For browsers that do not support gradients */
        background-color: $colour-light-grey;
        /* For Safari 5.1 to 6.0 */
        background-color: -webkit-linear-gradient($colour-gradient-light-from, $colour-gradient-light-to);
        background-color: -webkit-gradient(linear, left top, left bottom, from($colour-gradient-light-from), to($colour-gradient-light-to));
        /* For Firefox 3.6 to 15 */
        background-color: -moz-linear-gradient($colour-gradient-light-from, $colour-gradient-light-to);
        /* For Opera 11.1 to 12.0 */
        background-color: -o-linear-gradient($colour-gradient-light-from, $colour-gradient-light-to);
        /* Standard syntax */
        background-color: linear-gradient($colour-gradient-light-from, $colour-gradient-light-to);
        color: $colour-black;
        font-weight: 400;

        /* colour */
        .txt-grey {
            color: $colour-dark-grey;
        }
        .outline-grey {
            background-color: $colour-transparent;
            border: $width-border solid $colour-dark-grey;
            color: $colour-dark-grey;
        }

        /* ELEMENT */
        nav ul li a {
            color: $colour-grey;
        }

        /* h & txt */
        h1,
        h2,
        h3,
        h4 {
            font-weight: 400;
        }
        h5,
        h6 {
            font-weight: 300;
        }

        a {
            font-weight: 500;
        }

        .txt {
            &-light {
                font-weight: 400;
            }
            &-normal {
                font-weight: 500;
            }
            &-bold {
                font-weight: 700;
            }
        }

        /* input */
        .ua-input {
            color: $colour-dark-black;
        }

        /* btn */
        .ua-btn {
            &.default {
                &.contrast {
                    &:not(.disabled) {
                      background-color: $colour-dark-grey;
                      border-color: $colour-dark-grey;
                      color: $colour-light-grey;
                    }
                    &:active,
                    &:hover,
                    &:focus {
                        background-color: $colour-dark-black;
                        border-color: $colour-dark-black;
                        color: $colour-white;
                    }
                }
            }
            &.outline {
                &.text {
                    border-color: $colour-black;
                    color: $colour-black;

                    &:active,
                    &:hover,
                    &:focus {
                        border-color: $colour-dark-black;
                        color: $colour-dark-black;
                    }
                }
                &.contrast {
                    &:not(.disabled) {
                        border-color: $colour-black;
                        color: $colour-black;
                    }
                    &:active,
                    &:hover,
                    &:focus {
                        border-color: $colour-dark-black;
                        color: $colour-dark-black;
                    }
                }
            }
        }

        /* input */
        input {
            color: $colour-dark-black;
        }
        select {
            text-shadow: $txt-shadow-dark;

            option {
                background-color: $colour-light-grey;
                color: $colour-dark-black;
            }
        }

        /* tab */
        .ua-tab {
            li {
                span {
                    color: $nearly-black;
                }
                &.active {
                    span {
                        color: $colour-dark-black;

                        &:hover,
                        &:focus {
                            color: $colour-dark-black;
                        }
                    }
                }
            }
        }
        .ua-btn-tab {
            border-color: $colour-grey;
            color: $nearly-black;

            &.active,
            &:hover,
            &:focus {
                border-color: $colour-grey;
                color: $nearly-black;
            }

            li {
                background-color: $colour-light-grey;

                &.active {
                    a {
                        background-color: $colour-dark-white;
                        color: $colour-dark-black;
                    }
                    &:hover,
                    &:focus {
                        a {
                            color: $colour-dark-black;
                        }
                    }
                }
                &:hover,
                &:focus {
                    a {
                        color: $colour-dark-black;
                    }
                }
                a {
                    border-color: $colour-dark-white;
                    color: $nearly-black;
                }
            }
        }
        .tab-content {
            background-color: $colour-grey;
        }
        .ua-vertical-tab {
            li {
                .ua-tab-title {
                    background-color: $colour-light-grey;
                    border-color: $colour-dark-white;
                    color: $nearly-black;
                }
                &:hover,
                &:focus,
                &.active {
                    .ua-tab-title {
                        color: $colour-black;
                    }
                }
                &.active {
                    .ua-tab-title {
                        background-color: $colour-dark-white;
                    }
                }
            }
            &-pane {
                background-color: $colour-dark-white;
            }
            &-content {
                background-color: $colour-light-grey;
            }
        }
        .ua-horizontal-tab {
            .ua-tab-item {
                &:hover:not(.add-item-btn),
                &:focus:not(.add-item-btn) {
                    > span {
                        background-color: rgba(255, 255, 255, 0.5);
                        border-color: rgba(255, 255, 255, 0.5);
                        color: $nearly-black;
                    }
                }
                &.active {
                    &:hover,
                    &:focus {
                        > span {
                            background-color: $colour-dark-white;
                            border-color: $colour-dark-white;
                            color: $nearly-black;
                        }
                    }
                    > span {
                        background-color: $colour-dark-white;
                        border-color: $colour-dark-white;
                    }

                }
                > span {
                    background-color: $colour-light-grey;
                    border-color: $colour-grey;
                    color: $colour-black;
                }
            }
            &-content {
                background-color: $colour-light-grey;
            }
            &-pane {
                background-color: $colour-dark-white;
            }
        }
        /* box */
        .ua-box {
            &.default:not(.ua-tooltip) {
                background-color: $colour-dark-white;
                border-top-width: 0;
                border-left-width: 0;
            }
            &:not(.outline) {
                border-color: $border-dark;
                -webkit-box-shadow: 1px 1px 2px 1px $box-shadow-dark;
                -moz-box-shadow: 1px 1px 2px 1px $box-shadow-dark;
                -khtml-shadow: 1px 1px 2px 1px $box-shadow-dark;
                box-shadow: 1px 1px 2px 1px $box-shadow-dark;
            }
            &.outline {
                border-color: $colour-dark-grey;
            }
            &.ua-tooltip {
                border-top-width: 1px;
                border-left-width: 1px;
                -webkit-box-shadow: 1px 1px 2px 1px $box-shadow-light;
                -moz-box-shadow: 1px 1px 2px 1px $box-shadow-light;
                -khtml-shadow: 1px 1px 2px 1px $box-shadow-light;
                box-shadow: 1px 1px 2px 1px $box-shadow-light;
            }
            &.portfolio-value-settings {
                background-color: $colour-light-grey;
                -webkit-box-shadow: 2px 2px 4px 2px $box-shadow-dark;
                -moz-box-shadow: 2px 2px 4px 2px $box-shadow-dark;
                -khtml-shadow: 2px 2px 4px 2px $box-shadow-dark;
                box-shadow: 2px 2px 4px 2px $box-shadow-dark;
            }
        }

        /* progress */
        .ua-progress {
            background-color: $colour-transparent;
            border: $width-border solid $border-dark;
        }

        /* table */
        .data-table .fullscreen,
        .filter-table.fullscreen {
            background-color: $colour-light-grey;
        }

        .ReactTable {
            .rt-thead {
                .rt-th:first-child,
                .rt-td:first-child,
                .rt-th,
                .rt-td,
                .rt-th:last-child {
                    background-color: $colour-dark-white;
                }
            }
            .rt-tbody {
                .rt-tr-group {
                    border-bottom: $width-border solid $border-dark;
                }
                .rt-td:first-child,
                .rt-td {
                    background-color: $colour-dark-white;
                }
            }
            &.table-component {
                .rt-thead {
                    .rt-th {
                        .data-table-header {
                            &.sort {
                                color: $colour-black;
                                font-weight: 600;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        .ai-report,
        .article-view,
        .ua-box .ReactTable {
            tbody {
                tr {
                    &:nth-child(even) {
                        td {
                            background-color: $table-bg-light-even;
                        }
                    }
                }
            }
            .rt-tbody {
                .rt-tr-group {
                    &:nth-child(even) {
                        .rt-td{
                            background-color: $table-bg-light-even;
                        }
                    }
                }
            }
        }
        .playlist-item.selected .playlist-item-title-container {
            background-color: rgba(255, 165, 0, 0.8);
        }
        /* pagination */
        .pagination {
            border-color: $border-dark;
            color: $colour-dark-black;

            > li {
                > a,
                > span {
                    color: $colour-dark-black;
                }
                &.disabled {
                    > a,
                    > span {
                        color: $colour-dark-black;
                    }
                }
            }
        }

        /* modal */
        .modal {
            color: $colour-dark-black;

            .modal-dialog .modal-content {
                background-color: $colour-dark-white;
                border-color: $border-dark;
                -webkit-box-shadow: 3px 0 3px 0 $box-shadow-dark;
                -moz-box-shadow: 3px 0 3px 0 $box-shadow-dark;
                -khtml-shadow: 3px 0 3px 0 $box-shadow-dark;
                box-shadow: 3px 0 3px 0 $box-shadow-dark;

                .modal-header {
                    border-bottom-color: $box-shadow-dark;

                    .close {
                        color: $colour-dark-grey;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $colour-dark-black;
                        }
                    }
                }
            }
            &.range-switch-modal {
                .modal-dialog .modal-content {
                    .switch-name {
                        color: $colour-dark-black;
                    }
                    .switch-type {
                        border-color: $colour-dark-black;
                    }
                }
            }
        }

        .ua-backdrop {
            background-color: rgba(0, 0, 0, 0.5);

            &.tooltip-backdrop {
                background-color: $colour-transparent;
            }
        }

        /* LAYOUT */
        /* page */
        .fixed-bar {
            background-color: $colour-light-grey;
        }
        .login-page {
            .page-header.white-btn {
                background-color: $colour-dark-grey;
            }
            .white-btn .menu-toggler {
                z-index: 2;
                color: $colour-white;
                text-align: center;
            }
        }
        .ua-page {
            background-color: $colour-light-grey;
            // color: $colour-dark-black;

            .ua-content {
                .lab-setting-option {
                    color: $colour-white;

                    .ua-input {
                    // .dragging-helper {
                        color: $colour-white;
                    }
                }
            }
        }

        /* toolbar */
        .toolbar {
            border-bottom: $width-border solid $colour-grey;
        }
        /* e-com */
        .product-box {
            a {
                color: $colour-black;
            }
            .product-name {
                // color: $colour-dark-black;
                font-weight: 600;
            }
        }

        /* auth */
        .auth-content-title {
            color: $colour-black;

            h2 {
                color: $colour-dark-black;
            }
        }

        /* profile */
        .mobile-title {
            color: $colour-dark-black;
        }

        /* my-lab */
        .lab-list {
            a {
                color: $colour-black;

                &:hover,
                &:focus,
                &:active {
                    color: $colour-dark-black;
                }
            }
        }

        .lab-setting-page {
            .lab-setting-option .add-lab .products-selection .products-selection-header {
                background-color: #747474;
            }
        }

        /* dashboard */
        .stock-container,
        .article-list {
            &-search,
            &-pagination {
                background-color: $colour-light-grey;
            }
            .article-container-wrap {
                &.top-article {
                    background-color: #fda38757;
                }
            }
            .search-input {
                background-color: $colour-dark-white;
                border-color: $colour-grey;
                border: none;
            }
        }
        .single-switch-tab,
        .single-switch-content {
            background-color: $colour-light-grey;
        }
        .article-view {
            table {
                background-color: $colour-dark-white;

                thead {
                    th:first-child,
                    td:first-child,
                    th,
                    td {
                        color: $colour-dark-black;
                        transition: color 0s;

                        &:hover {
                            color: $colour-light-orange;
                        }
                    }
                }
                th,
                tr td {
                    background-color: $colour-dark-white;
                }
            }
        }
        .dashboard-dropdown {
            &-menu {
                background-color: $colour-light-grey;

                &-container .ua-box {
                    background-color: $colour-light-grey;

                    .dashboard-dropdown-labBtn .lab-btn-wrap {
                        background-color: $colour-light-grey;
                        background-color: rgba(black, 0.2);
                    }
                }
                &-item {
                    .dropdown-item {
                        border-color: $colour-dark-grey;
                        color: $colour-dark-grey;
                    }
                }
            }
            .ua-triangle {
                border-top-color: $nearly-black;
            }
        }
        .ua-dashboard .lab-btn-modal-wrap .lab-btn-modal-contents,
        .ai-single .search-input-box .search-input,
        .ai-list,
        .ua-dashboard .ai .module-right .answer,
        .ua-dashboard .ai .module-right .question-input,
        .ua-dashboard .ai-prompt .question-submit,
        .ua-dashboard .ai-prompt .tab-container .ua-btn.round {
            background-color: rgba($colour-dark-black, 0.06);
        }
        .ua-dashboard .ai-prompt .question-submit,
        .ua-dashboard .ai-prompt .tab-container .ua-btn.round {
            border-color: rgba($colour-dark-black, 0.06);

            &:active,
            &:hover,
            &:focus {
                background-color: $colour-grey;
                border-color: $colour-grey;
                color: $colour-dark-black;
            }
            &.disabled {
                background-color: $colour-transparent;
                border-color: $colour-grey;
            }
        }
        .ua-dashboard{
            .shortcutKey{
                .shortcutKey-hint{
                    background-color: $colour-dark-white;
                    border: 1px solid $colour-grey;
                }
                &-k--ALT, &-k--CTRL, &-k--F{
                    background-color: $colour-black;
                    opacity: 1;
                }
            }
        }
        &.Mobile {
            .module-left .content-btn {
                &:hover{
                    color: $colour-dark-grey;
                }
            }
        }
        .module-right {
            .conclusion-wrap--sm {
                background-color: rgba($colour-dark-black, 0.06);
            }
            .module-right-header {
                background-color: $colour-dark-white;

                i:hover {
                    background-color: rgba($colour-dark-black, 0.2);
                }
            }
            .conclusion {
                h1, h2, h3, h4, h5 {
                    color: $colour-dark-black;
                    font-weight: 600;
                }
                p {
                    color: $colour-dark-grey;

                    strong {
                        color: $colour-dark-black;
                        font-weight: 600;
                    }
                }
                li {
                    color: $colour-dark-grey;

                    strong {
                        color: $colour-dark-black;
                        font-weight: 600;
                    }
                }
            }
        }
        .content-group {
            .content-title {
                font-weight: 500;
                color: $colour-dark-black;
            }
            .content-btn {
                color: $colour-dark-grey;
            }
        }
        .ai-report {
            .summary-table-text {
                font-weight: 400;
                color: $colour-dark-grey;
            }
            .summary-table-value {
                font-weight: 600;
            }
        }
        .ua-highcharts {
            &-note {
                &__title {
                    color: $colour-grey;
                }
            }
        }
        .filter-btn {
            color: $colour-dark-black;
        }
        .solidgauge-tooltip {
            color: $colour-black;
        }
        .news-title {
            color: $colour-black;
            font-weight: 400;

            .news-date {
                color: $colour-black;
                font-weight: 400;
            }
        }
        .account-btn {
            color: $colour-dark-grey;

            &.sub-account {
                color: $colour-dark-grey;
            }
        }
        .chart-function-list {
            .chart-function-item {
                &-name {
                    &.function-item {
                        color: $colour-blue;
                    }
                }
                &-value {
                    &.chart-function-select,
                    &.chart-function-input {
                        border-color: $colour-grey;
                    }
                }
            }
        }
        .column {
            .keyword {
                background-color: $colour-grey;
            }
        }
        .stock-box {
            background-color: $colour-dark-grey;
            color: $nearly-white;
        }
        &.Mobile {
            a.detail-btn-mobile {
                color: $colour-black;
            }
            .company-info-mobile {
                .stock-name,
                .small-table-row {
                    border-bottom-color: $colour-black;
                }
            }
        }

        /* playground */
        .result-exist-list {
            .ua-form .checkbox .ua-checkbox .ua-checkbox-icon {
                border-color: $colour-grey;
            }
            .sort-box {
                border-color: $colour-dark-black;
                color: $colour-dark-black;
            }
        }

        /* footer */
        .page-footer {
            border-top: $width-border solid $colour-black;
        }
        .ua-body .ua-page {
            .page-footer {
                color: $colour-dark-black;

                a {
                    color: $colour-dark-black;
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    body {
        &.dark {
            .result-exist-list {
                background-color: $colour-black;
            }
        }
        &.light {
            .result-exist-list {
                background-color: $colour-light-grey;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    body.light {
        .ua-app.menu-hidden .logo-container .menu-toggler {
            color: $colour-dark-black;
        }
    }
}

@media only screen and (max-width: 520px) {
    body {
        &.dark {
            /* page */
            /* LAYOUT */
            .ua-app.menu-hidden .page-header.logged-in-header {
                background-color: $colour-transparent;
            }

            /* dashboard */
            .account-btn-mobile {
                &:hover,
                &:focus {
                    border-color: $colour-white;
                    color: $colour-white;
                }
            }
            .switch-btn-mobile {
                &:hover,
                &:focus {
                    border-color: $colour-white;
                    color: $colour-white;
                }
            }
        }
        &.light {
            .menu-toggler {
                color: $colour-dark-black;
            }
            .ua-app.menu-hidden .page-header.logged-in-header {
                background-color: $colour-transparent;
            }
            .switch-btn-mobile {
                border-color: $colour-orange;
                color: $colour-orange;

                &:hover,
                &:focus {
                    border-color: $colour-orange;
                    color: $colour-orange;
                }
            }

            /* dashboard */
            .account-btn-mobile {
                border-color: $colour-orange;
                color: $colour-orange;

                &:hover,
                &:focus {
                    border-color: $colour-orange;
                    color: $colour-orange;
                }
            }
        }
    }
}

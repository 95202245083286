@import "../animation";
@import "../config";

@mixin page-loading-animation($name) {
    -webkit-animation: $name 3s linear infinite;
    -moz-animation: $name 3s linear infinite;
    -ms-animation: $name 3s linear infinite;
    -o-animation: $name 3s linear infinite;
    animation: $name 3s linear infinite;
}

@include keyframes(rotateEast) {
    0% {
        transform: rotate(-25deg);
    }
    100% {
        transform: rotate(115deg);
    }
}
@include keyframes(rotateWest) {
    0% {
        transform: rotate(-25deg);
    }
    100% {
        transform: rotate(130deg);
    }
}

.page-loading {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    // padding: 20px;
    -webkit-border-radius: 0 0 $border-radius-box $border-radius-box;
    -moz-border-radius: 0 0 $border-radius-box $border-radius-box;
    -khtml-border-radius: 0 0 $border-radius-box $border-radius-box;
    border-radius: 0 0 $border-radius-box $border-radius-box;

    .circle-container {
        position: relative;

        // img
        .logo-loading {
            position: absolute;
            top: 0;
            left: 0;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 100px;

            &:not(.no-border) {
                background-color: $colour-transparent;
                // background-image: url(../../img/logo/logo_light.svg);
                background-size: 150px;
            }
        }
    }
    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .half-clip {
            position: absolute;
            right: 0;
            width: 50px;
            height: 100px;
            overflow: hidden;
            transform-origin: left;

            &.left {
                -webkit-transform: rotate(145deg);
                -moz-transform: rotate(145deg);
                -ms-transform: rotate(145deg);
                -o-transform: rotate(145deg);
                transform: rotate(145deg);

                .half-circle {
                    border-top-color: $colour-orange;
                    border-left-color: $colour-orange;

                    &.clipped {
                        @include page-loading-animation(rotateEast);
                    }
                }
            }
            &.right {
                -webkit-transform: rotate(-25deg);
                -moz-transform: rotate(-25deg);
                -ms-transform: rotate(-25deg);
                -o-transform: rotate(-25deg);
                transform: rotate(-25deg);

                .half-circle {
                    border-top-color: $colour-white;
                    border-left-color: $colour-white;

                    &.clipped {
                        @include page-loading-animation(rotateEast);
                    }
                }
            }
            .half-circle {
                position: absolute;
                right: 0;
                height: 100%;
                border-style: solid;
                border-color: $colour-transparent;
                border-width: 4.8px;
                -webkit-border-radius: $border-radius-circle;
                -moz-border-radius: $border-radius-circle;
                -khtml-border-radius: $border-radius-circle;
                border-radius: $border-radius-circle;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                &.clipped {
                    width: 200%;
                }
            }
        }
    }
}

@keyframes content-loader-animation {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}

.content-loader {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: content-loader-animation;
    animation-timing-function: linear;
    background-color: $colour-light-grey;
    background: linear-gradient(to right, $box-shadow-dark 8%, $table-bg-even 18%, $box-shadow-dark 33%);
    background-size: 800px 104px;
    height: 15px;
    margin-top: 15px;
    position: relative;
    width: calc(100% - 10px);
}

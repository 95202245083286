@import "../config";

$menu-toggler: 40px;

.page-header {
    z-index: 2;
    position: fixed;
    width: $width-side-menu;
    height: $height-toolbar;
    top: 0;
    left: 0;
    right: 0;

    .logo-container {
        width: 100%;
        height: $height-toolbar;
        padding-left: 12px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;;

        .logo {
            width: 100%;
            height: $height-toolbar;
            background: $colour-transparent url(../../img/logo/logo_colour_horizontal.svg) no-repeat;
            background-size: 53%;
            background-position: 61px 8px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .menu-toggler {
            color: $colour-dark-grey;
        }
    }
    .menu-toggler {
        z-index: 3;
        position: absolute;
        width: $menu-toggler;
        height: $menu-toggler;
        top: 0;
        left: 0;
        padding-top: 5px;
        padding-left: 5px;
        font-size: 2.5em;
        letter-spacing: normal;
    }
    // (login page)
    .top-bar {
        position: absolute;
        height: $height-toolbar;
        top: 0;
        right: 0;
        margin-top: 0;
        padding-top: 18px;
        background-color: $colour-dark-grey;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        > div {
            position: relative;
            padding-left: 0;
            padding-right: 0;
        }
        .header-btn {
            position: relative;
            color: $colour-white;

            &:hover,
            &:focus,
            &:active {
                color: $colour-white;
            }
            i {
                font-size: 18px;
            }
            small {
                position: relative;
                top: -8px;
            }
        }
    }
}

.ua-app {
    .page-header {
        &.logged-in-header {
            width: $width-side-menu;
        }
    }
    &.menu-hidden {
        .page-header {
            &.logged-in-header {
                background-color: $colour-white;
            }
        }
    }
    &.login-page {
        .page-header {
            width: 100%;
            height: $height-toolbar;
            background-color: $colour-dark-grey;

            .logo-container .logo {
                width: $width-side-menu;
                background-image: url(../../img/logo/logo_light_horizontal.svg);
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .page-header {
        width: 100%;

        .logo-container {
            width: $width-side-menu;

            .menu-toggler {
                position: absolute;
                top: 12px;
                padding: 0;
                font-size: 2em;
            }
        }
    }
    .ua-app {
        &.menu-hidden {
            .page-header {
                .logo-container .logo {
                    display: none;
                }
                .top-bar {
                    div a,
                    .header-btn {
                        color: $colour-white;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $colour-white;
                        }
                    }
                }
                &.logged-in-header {
                    width: $menu-toggler;
                    height: $menu-toggler;
                }
            }
        }
        &.login-page {
            .page-header {
                .logo-container {
                    width: 180px;

                    .logo {
                        display: none;
                    }
                }
            }
        }
        &:not(.login-page) {
            .page-header {
                width: $menu-toggler;
            }
        }
    }
}

@import "./config";

html,
body {
    position: fixed;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
}
body {
    direction: ltr;
    width: 100%;
    height: 100vh;
    /* For browsers that do not support gradients */
    background-color: $colour-black;
    /* For Safari 5.1 to 6.0 */
    background-color: -webkit-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
    /* For Firefox 3.6 to 15 */
    background-color: -moz-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
    /* For Opera 11.1 to 12.0 */
    background-color: -o-linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
    /* Standard syntax */
    background-color: linear-gradient($colour-gradient-dark-from, $colour-gradient-dark-to);
    color: $nearly-white;
    font-family: $font-family;
    font-size: $font-size;
    overflow-y: hidden;
    letter-spacing: 1px;
    line-height: $line-height;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    /* A & BTN */
    button {
        outline: none !important;
        -ms-touch-action: none!important;
        touch-action: auto;

        &:focus,
        &:active {
            outline: none !important;
        }
    }
    embed,
    input::-moz-focus-inner,
    object {
        outline: none !important;
    }
    iframe {
        border: none;
    }
    a {
        color: $colour-orange;
        cursor: pointer;
        vertical-align: middle;
        text-decoration: none !important;
        -ms-touch-action: none !important;
        touch-action: auto;

        &:hover,
        &:focus,
        &:active {
            color: $colour-light-orange;
            outline: none !important;
        }
    }
    div {
        outline: none !important;

        &:focus,
        &:active {
            outline: none !important;
        }
    }
    * {
        outline: none !important;

        &:focus,
        &:active {
            outline: none !important;
        }
    }
    /* INPUT */
    input {
        background-color: $colour-transparent;
        line-height: $line-height;
        -ms-touch-action: none!important;
        touch-action: auto;
    }
    select {
        background-color: $colour-transparent;
        background-image: none;
        border: none;
        color: $colour-transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -khtml-shadow: none;
        box-shadow: none;
        cursor: pointer;
        outline: none;
        text-overflow: ellipsis;
        line-height: $line-height;

        &:focus {
            border: none;
            outline: none;
            -webkit-tap-highlight-color: $colour-transparent;
        }
    }
    optgroup,
    textarea {
        line-height: $line-height;
    }
    textarea {
        resize: none;
    }

    /* HTML */
    header {
        letter-spacing: 1.4px;
    }
    nav {
        ul {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 13.5px;
            line-height: 0.5em;
            list-style: none;

            ul {
                margin: 0;
                padding: 5px 0;

                li {
                    margin: 0;
                    padding: 0;

                    > a {
                        padding: 2px 27px;
                        font-size: 14px;
                        font-weight: 400;
                        outline: none;

                        > i {
                            position: relative;
                            margin-right: 5px;
                        }
                    }
                }
            }

            li a {
                display: block;
                position: relative;
                padding: 6px 10px 6px 0;
                font-size: 14px;
                font-weight: 400;
                line-height: $line-height;
                text-decoration: none;
            }
        }
    }
    label {
        font-weight: 300;
        vertical-align: middle;
        letter-spacing: 2px;
    }
    i {
        vertical-align: middle;
    }
    hr {
        margin: 0;
        border-top: $width-border solid $colour-grey;
    }
    em {
        font-style: normal;
    }
    iframe {
        width: 100%;
    }
    blockquote {
        border-left: 5px solid $colour-light-grey;
    }

    /* FONT & TEXT */
    h1 {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 300;
        line-height: $line-height;
        letter-spacing: 1.4px;
    }
    h2 {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 300;
        line-height: $line-height;
        letter-spacing: 1.4px;
    }
    h3 {
        margin: 10px 0;
        font-size: 15px;
        font-weight: 300;
        line-height: $line-height;
        letter-spacing: 1.4px;
    }
    h4 {
        margin: 10px 0;
        font-size: $font-size;
        font-weight: 300;
        line-height: $line-height;
        letter-spacing: 1.4px;
    }
    h5 {
        font-size: 13px;
        font-weight: 200;
    }
    h6 {
        font-size: 12.5px;
        font-weight: 200;
    }
    p, ul {
        margin: 0 0 9px;
    }
    small {
        font-size: 13px;
        vertical-align: baseline;
    }
    strong {
        font-weight: 500;
    }

    &.Mobile {
        h1 {
            font-size: 16px;
        }
        h2 {
            font-size: 15px;
        }
        h3 {
            font-size: 14px;
        }
        h4 {
            font-size: 14px;
        }
    }

    .vl {
        border-left: $width-border solid $colour-grey;
    }

    .dragging-helper {
        font-weight: bolder;
    }

    .remark-green {
        color: $colour-green;
    }
    .remark-red {
        color: $colour-red;
    }

    // app
    .app-bg {
        background-color: #2f3441;
        height: 100vh;

        .module-tool {
            text-align: center;
            padding-top: 10px;

            .ua-btn-tab,
            .ua-btn-tab.active {
                background-color: #1d222a;
                color: #ffffff99;
                border: 1px solid #1d222a;
                color: rgba(255, 255, 255, 0.6);
                font-size: 12.5px;
                height: 30px;
                padding: 8px;
                line-height: 1;
            }
            .ua-btn-tab:first-child {
                border-top-left-radius: 5px;
            }
            .ua-btn-tab:last-child {
                border-bottom-right-radius: 5px;
            }
        }
    }
    .app-product-1 {
        .module-tool {
            .ua-btn-tab.active {
                color: #67b4ff;
                box-shadow: unset;
            }
        }
    }
    .app-product-2 {
        .module-tool {
            .ua-btn-tab.active {
                color: #ffC767;
                box-shadow: unset;
            }
        }
    }

    // loading ani
    .spinner {
        font-size: 24px;
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
    }
    .spinner.center {
        position: absolute;
        left: 0;
        right: 0;
        top: -250px;
        bottom: 0;
        margin: auto;
    }
    .spinner .spinner-blade {
        position: absolute;
        left: 0.4629em;
        bottom: 0;
        width: 0.15em;
        height: 0.4em;
        border-radius: 0.0555em;
        background-color: transparent;
        -webkit-transform-origin: center -0.2222em;
            -ms-transform-origin: center -0.2222em;
                transform-origin: center -0.2222em;
        -webkit-animation: spinner-fade 1s infinite linear;
                animation: spinner-fade 1s infinite linear;
    }
    .spinner .spinner-blade:nth-child(1) {
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    .spinner .spinner-blade:nth-child(2) {
        -webkit-animation-delay: 0.125s;
                animation-delay: 0.125s;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
    }
    .spinner .spinner-blade:nth-child(3) {
        -webkit-animation-delay: 0.250s;
                animation-delay: 0.250s;
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);
    }
    .spinner .spinner-blade:nth-child(4) {
        -webkit-animation-delay: 0.375s;
                animation-delay: 0.375s;
        -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
                transform: rotate(135deg);
    }
    .spinner .spinner-blade:nth-child(5) {
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    .spinner .spinner-blade:nth-child(6) {
        -webkit-animation-delay: 0.625s;
                animation-delay: 0.625s;
        -webkit-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
                transform: rotate(225deg);
    }
    .spinner .spinner-blade:nth-child(7) {
        -webkit-animation-delay: 0.75s;
                animation-delay: 0.75s;
        -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
                transform: rotate(270deg);
    }
    .spinner .spinner-blade:nth-child(8) {
        -webkit-animation-delay: 0.875s;
                animation-delay: 0.875s;
        -webkit-transform: rotate(315deg);
            -ms-transform: rotate(315deg);
                transform: rotate(315deg);
    }

    @-webkit-keyframes spinner-fade {
        0% {
          background-color: #69717d;
        }
        100% {
          background-color: transparent;
        }
    }

    @keyframes spinner-fade {
        0% {
            background-color: #69717d;
        }
        100% {
            background-color: transparent;
        }
    }
    .a-loading {
        &-android {
            position: absolute;
            top: 20vh;
            left: 40vw;
            animation: rotate 2s linear infinite;
            transform-origin: center center;
            > circle {
                display: inline-block;
                animation: dash 1500ms ease-in-out infinite;
                stroke-linecap: round;
                color: currentColor;
            }

            @keyframes rotate {
                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes dash {
                0% {
                    stroke-dasharray: 1, 200;
                }

                50% {
                    stroke-dasharray: 100, 200;
                    stroke-dashoffset: -45;
                }

                100% {
                    stroke-dasharray: 100, 200;
                    stroke-dashoffset: -124;
                }
            }
        }
    }
}

@media only screen and (max-width: 979px) {
    body .container {
        width: 100%;
        border: none;
    }
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
    select {
        padding: 5px\9;
        background-image: none\9;
    }
}

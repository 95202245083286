@import "../config";

.ai-chat {
    padding: 0 15px;

    .questions {
        margin-bottom: 15px;

        .question-submit {
            &.enabled-show {
                background-color: $colour-orange;
                color: $colour-white;
                box-sizing: border-box;
            }
        }
        .questions-clearBtn {
            width: 100%;
            text-align: right;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
            color: $colour-orange;
            margin-top: 15px;
        }
    }
    .question-inputs {
        margin-top: 20px;

        // .inputs {
        //     button {
        //         margin: 0;
        //     }
        //     input {
        //         width: calc(100% - 60px);
        //         margin-right: auto;
        //     }
        // }
    }
    .answers .inputs {
        // max-height: 350px;
        // overflow: hidden scroll;
        margin-top: 0;

        ol {
            padding-left: 25px;

            ul {
                padding-left: 10px;
            }
        }
        ul {
            padding-left: 25px;
        }
        li {
            margin: 6px 0;
        }
    }
}

body.Mobile {
    .ai-chat {
         padding: 0;
    }
}

@import "./animation";
@import "./config";

$border-radius-progress: 2px;
$box-shadow: rgba(0, 0, 0, 0.3);
$container-size: 23px;
$icon-size: 18px;

/* BTN */
.ua-btn {
    -khtml-border-radius: $border-radius-btn;
    -moz-border-radius: $border-radius-btn;
    -webkit-border-radius: $border-radius-btn;
    border-radius: $border-radius-btn;
    border-style: solid;
    border-width: $width-border;
    cursor: pointer;
    font-display: optional;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    margin: 10px 0;
    padding: 6px 12px;
    text-align: center;

    &:active,
    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }
    &.default {
        &:not(.disabled) {
            background-color: $colour-orange;
            border-color: $colour-orange;
            color: $colour-white;
        }
        &:active,
        &:hover,
        &:focus {
            background-color: $colour-light-orange;
            border-color: $colour-light-orange;
            color: $colour-white;
            text-decoration: none;
            outline: none;
        }
        &.ua-box-shadow:not(.disabled) {
            border-top-color: $box-shadow-light;
            border-left-color: $box-shadow-light;
        }
    }
    &.outline {
        background-color: $colour-transparent;

        &:not(.disabled) {
            border-color: $colour-orange;
            color: $colour-orange;
        }
        &:active,
        &:hover,
        &:focus {
            background-color: $colour-transparent;
            border-color: $colour-light-orange;
            color: $colour-light-orange;
            outline: none;
        }
    }
    &.round {
        margin: 3px 5px;
        border-radius: 18.5px;
    }
    &.disabled {
        background-color: $colour-transparent;
        border-color: $colour-grey;
        color: $colour-grey;
        cursor: initial;

        &:hover,
        &:focus {
            background-color: $colour-transparent;
            border-color: $colour-grey;
            color: $colour-grey;
            cursor: initial;
        }
    }
    &.question-submit{
        &.enabled-show{
            background-color: $colour-orange;
            color: $colour-white;
            box-sizing: border-box;
        }
    }
}
a.ua-btn {
    padding: 6.5px 13.5px 6.5px 15px;
    vertical-align: baseline;
}
div.ua-btn {
    padding: 6.5px 15px; // height: 36px
}
div.ua-btn.disabled {
    padding: 8px 15px; // height: 36px
}
span.ua-btn {
    padding: 6.5px 15px; // height: 36px
}
.product-btn {
    > .ua-btn {
        margin-right: 10px;
    }
}

.ua-txt-btn {
    color: $colour-orange;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none !important;
    -ms-touch-action: none !important;
    touch-action: auto;

    &:hover,
    &:focus,
    &:active {
        color: $colour-light-orange;
        outline: none !important;
    }
}

/* BOX */
.ua-box {
    @include ua-box;

    &-header {
        padding: 0 10px 10px;
    }
    &.outline {
        background-color: $colour-transparent;
    }
    &.white {
        background-color: $colour-white;
        border-color: $colour-transparent;
    }
}
.ua-box-shadow {
    -webkit-box-shadow: 1px 1px $box-shadow-dark;
    -moz-box-shadow: 1px 1px $box-shadow-dark;
    -khtml-shadow: 1px 1px $box-shadow-dark;
    box-shadow: 1px 1px $box-shadow-dark;
}

/* FORM */
// mixin
@mixin checkbox-radio {
    display: flex;
    position: relative;
    padding-left: 0;
    background-color: $colour-transparent;
    background-image: none;
    border: none;
    cursor: pointer;
    align-items: center;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;

    &:hover {
        i.ua-checkbox-icon:not(.disabled),
        i.ua-radio-icon:not(.disabled) {
            border: $width-border solid $colour-light-orange;
        }
    }
    &.disabled {
        cursor: default;

        i.ua-checkbox-icon,
        i.ua-radio-icon {
            background-color: $colour-grey;
            padding: 0 1.5px;
        }
    }
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        left: -9999px;
        margin-left: -20px;

        &:checked + i {
            &::after {
                opacity: 1;
            }
        }
    }
    i.ua-checkbox-icon,
    i.ua-radio-icon {
        display: inherit;
        min-width: $container-size;
        width: $container-size;
        height: $container-size;
        background-color: $colour-white;
        border: $width-border solid rgba(200, 200, 200, 0.8);
        color: $colour-orange;

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: $icon-size;
            margin: 1.5px;
            opacity: 0;
        }
    }
    label {
        margin-left: 10px;
    }
    span {
        margin-left: 10px;
    }
}

.ua-form {
    &.is-not-editing {
        .inputs {
            > input,
            > textarea {
                &:focus {
                    border-color: $colour-grey;
                }
            }
        }
    }
    button {
        padding: 6.5px 12px;

        &.disabled {
            margin-top: 20px;
        }
    }
    em {
        display: block;
        margin-top: 5px;
        color: $colour-red;
        font-size: 13.5px;

        &::before {
            content: "\f12a";
            display: inline-block;
            margin: 3.5px 5px;
            font-family: $font-family-awesome;
            font-weight: 900;
            font-size: smaller;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            vertical-align: text-top;
            -moz-osx-font-smoothing: greyscale;
            -webkit-font-smoothing: antialiased;
        }
    }
    fieldset {
        position: relative;
        padding: 0;
        border: none;
    }
    .inputs {
        width: 100%;
        margin: 8px 0;

        input {
            @include input;
            display: inline-block;
            padding: 6.85px 10px;// height: 36px

            &:focus {
                border: $width-border solid rgba(255, 165, 0, 0.8);
                outline: none;
            }
        }
        > label {
            display: block;
            margin-bottom: 0;
        }
        > textarea {
            @include input;
            display: inline-block;
            padding: 5.75px 10px;
            resize: vertical;

            &:focus {
                border: $width-border solid rgba(255, 165, 0, 0.8);
                outline: none;
            }
        }
        &.password {
            i {
                position: absolute;
                right: 0;
                margin: 10px;
                cursor: pointer;
            }
        }
        .select {
            position: relative;
            display: inline-block;
            width: 120px;
            padding: 0;
            border: $width-border solid $colour-grey;
            overflow-x: hidden;
            overflow-y: hidden;
            vertical-align: middle;

            &:active {
                border: $width-border solid rgba(255, 165, 0, 0.7);
                outline: none;
            }
            > select {
                width: 100%;
                padding: 8.5px; // height: 36px

                option {
                    font-size: 13.5px;
                }
            }
        }
    }
    .checkbox {
        margin-top: 10px;
        cursor: pointer;

        .ua-checkbox {
            @include checkbox-radio;

            // &.with-validator {
            //     display: inline-block;
            // }
            i {
                &::after {
                    height: $icon-size;
                }
                &:not(.disabled) {
                    &::after {
                        top: 0;
                        left: 4px;
                        width: 12px;
                        border: solid $colour-orange;
                        border-width: 0 4px 4px 0;
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
                &.disabled {
                    &::after {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 20px;
                        height: 20px;
                        background-color: $colour-grey;
                    }
                }
            }
        }
    }
    .radio {
        margin-top: 10px;
        cursor: pointer;

        .ua-radio {
            @include checkbox-radio;

            &:hover {
                &.disabled {
                    &:hover i {
                        border-color: $colour-grey;
                    }
                }
            }
            i {
                -webkit-border-radius: $border-radius-circle;
                -moz-border-radius: $border-radius-circle;
                -khtml-border-radius: $border-radius-circle;
                border-radius: $border-radius-circle;

                &::after {
                    height: 18px;
                    background-color: $colour-orange;
                    -webkit-border-radius: $border-radius-circle;
                    -moz-border-radius: $border-radius-circle;
                    -khtml-border-radius: $border-radius-circle;
                    border-radius: $border-radius-circle;
                }
            }
        }
    }
    .submit {
        margin-top: 10px;
    }
    .book-submit.disabled {
        &.disabled {
            background-color: $colour-grey;
            border: none;
            color:$colour-white;
        }
    }
}

body {
    &.Mobile {
        .ua-form {
            &.add-caption {
                padding: 0 $space;
            }
        }
    }
}


.pic-setting {
    em {
        display: block;
        margin-top: 5px;
        color: $colour-red;
        font-size: 13.5px;

        &::before {
            content: "\f12a";
            display: inline-block;
            margin: 5px;
            font-family: $font-family-awesome;
            font-weight: 900;
            font-size: smaller;
            font-style: normal;
            font-variant: normal;
            // line-height: 1;
            text-rendering: auto;
            vertical-align: text-top;
            -moz-osx-font-smoothing: greyscale;
            -webkit-font-smoothing: antialiased;
        }
    }
}
.pic-update {
    em {
        display: block;
        margin-top: 5px;
        color: $colour-red;
        font-size: 13.5px;

        &::before {
            content: "\f12a";
            display: inline-block;
            margin: 5px;
            font-family: $font-family-awesome;
            font-weight: 900;
            font-size: smaller;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            vertical-align: text-top;
            -moz-osx-font-smoothing: greyscale;
            -webkit-font-smoothing: antialiased;
        }
    }
}


/* ROW */
.row {
    &::after {
        content: " ";
        display: table;
        clear: both;
    }
}

/* INPUT */
.ua-input {
    -webkit-border-radius: $border-radius-box;
    -moz-border-radius: $border-radius-box;
    -khtml-border-radius: $border-radius-box;
    border-radius: $border-radius-box;
}
input[type="range"] {
    &.ua-range {
        width: 100%;
        height: 7px;
        background-color: $nearly-white;
        border: $width-border solid $border-dark;
        -webkit-border-radius: 3.5px;
        -moz-border-radius: 3.5px;
        -khtml-border-radius: 3.5px;
        border-radius: 3.5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &::-webkit-slider-thumb {
        width: 16px;
        height: 16px;
        margin-top: -5px;
        background-color: $colour-orange;
        border: none;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -khtml-border-radius: 16px;
        border-radius: 16px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      margin-top: -5px;
      background-color: $colour-orange;
      border: none;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -khtml-border-radius: 16px;
      border-radius: 16px;
      cursor: pointer;
    }
    &:focus {
        outline: none;

        &::-webkit-slider-runnable-track {
            background-color: $colour-grey;
        }
    }
}

/* SWITCH */
.ua-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;

    input {
        width: 0;
        height: 0;
        opacity: 0;
    }
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -khtml-border-radius: 30px;
        border-radius: 30px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;

        &:before {
            content: "";
            position: absolute;
            height: 22px;
            width: 22px;
            bottom: 4px;
            background-color: $colour-white;
            -webkit-border-radius: $border-radius-circle;
            -moz-border-radius: $border-radius-circle;
            -khtml-border-radius: $border-radius-circle;
            border-radius: $border-radius-circle;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            -ms-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
        }
    }
    &.on {
        input {
            &:checked {
                .slider {
                    &:before {
                        -webkit-transform: translateX(20px) 1s;
                        -moz-transform: translateX(20px) 1s;
                        -ms-transform: translateX(20px) 1s;
                        -o-transform: translateX(20px) 1s;
                        transform: translateX(20px) 1s;
                    }
                }
            }
        }
        .slider {
            background-color: $colour-orange;

            &:before {
                left: unset;
                right: 4px;
            }
        }
    }
    &.off {
        input {
          &:focus {
              .slider {
                  background-color: $colour-grey;
              }
          }
          &:checked {
              .slider {
                  background-color: $colour-grey;

                  &:before {
                      -webkit-transform: translateX(0px);
                      -moz-transform: translateX(0px);
                      -ms-transform: translateX(0px);
                      -o-transform: translateX(0px);
                      transform: translateX(0px);
                  }
              }
          }
        }
        .slider {
            background-color: $colour-grey;

            &:before {
                left: 4px;
                right: unset;
            }
        }
    }
}

/* TAB */
.ua-tab {
    width: fit-content;
    margin: 0;
    padding-left: 0;
    background-color: $colour-transparent;
    overflow: scroll hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        height: 4px;
    }
    &-item {
        display: inline-block;
        margin-right: 2px;
        padding: 0;

        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:focus {
            border-bottom: 3px solid $colour-light-orange;
        }
        &.active {
            border-bottom: 3px solid $colour-orange;
            color: $colour-orange;

            &:hover,
            &:focus {
                border-bottom-color: $colour-light-orange;
                color: $colour-light-orange;
            }
        }
        .ua-tab-title {
            display: block;
            position: relative;
            padding: 15px;
            -webkit-border-radius: $border-radius-box $border-radius-box 0 0;
            -moz-border-radius: $border-radius-box $border-radius-box 0 0;
            -khtml-border-radius: $border-radius-box $border-radius-box 0 0;
            border-radius: $border-radius-box $border-radius-box 0 0;
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
        }
        .ua-circle {
            margin-top: 22px;
            margin-left: 10px;
        }
    }
    &-content {
        -webkit-border-radius: 0 0 $border-radius-box $border-radius-box;
        -moz-border-radius: 0 0 $border-radius-box $border-radius-box;
        -khtml-border-radius: 0 0 $border-radius-box $border-radius-box;
        border-radius: 0 0 $border-radius-box $border-radius-box;
    }
    &-pane {
        display: none;
        padding: 15px;

        &.active {
            display: block;
        }
    }
}

.ua-btn-tab {
    display: inline-block;
    padding: 7.55px 10px;
    background: $colour-transparent;
    border-width: $width-border;
    border-style: solid;
    font-size: 13.5px;

    &:first-child {
        border-top-left-radius: $border-radius-btn;
        border-bottom-left-radius: $border-radius-btn;
    }
    &:last-child {
        border-top-right-radius: $border-radius-btn;
        border-bottom-right-radius: $border-radius-btn;
    }
    &.active {
        background: $colour-transparent;
        -webkit-box-shadow: inset 0 5px 10px $box-shadow;
        -moz-box-shadow: inset 0 5px 10px $box-shadow;
        box-shadow: inset 0 5px 10px $box-shadow;

        &:hover,
        &:focus {
            background: $colour-transparent;
            -webkit-box-shadow: inset 0 5px 10px $box-shadow;
            -moz-box-shadow: inset 0 5px 10px $box-shadow;
            box-shadow: inset 0 5px 10px $box-shadow;
        }
    }
}
.ua-form .ua-btn-tab {
    padding: 7.55px 10px;
}

.ua-vertical-tab {
    width: 20%;
    float: left;

    li {
        display: block;
        margin-right: 0;
        padding: 0;
        -webkit-box-shadow: 0 2px 2px 0 $box-shadow-dark;
        -moz-box-shadow: 0 2px 2px 0 $box-shadow-dark;
        box-shadow: 0 2px 2px 0 $box-shadow-dark;

        &:hover,
        &:focus {
            // margin-left: -10px;
            border: none;
        }
        &.active {
            // margin-left: -10px;
            border: none;

            &:hover,
            &:focus {
                // margin-left: -10px;
                border: none;
            }
        }
        &:not(:first-child) {
            // > a,
            > span {
                border-top: none;
            }
        }
        // a,
        .ua-tab-title {
            margin-right: 0;
            // padding: 15px;
            // border: none;
            border-width: $width-border;
            border-style: solid;
            border-right: none;

            -webkit-border-radius: $border-radius-box 0 0 $border-radius-box;
            -moz-border-radius: $border-radius-box 0 0 $border-radius-box;
            -khtml-border-radius: $border-radius-box 0 0 $border-radius-box;
            border-radius: $border-radius-box 0 0 $border-radius-box;
            font-size: 14.5px;
            text-align: center;
        }
    }
    &-pane {
        width: 80%;
        margin-left: 20%;
        -webkit-border-radius: 0 $border-radius-box $border-radius-box $border-radius-box;
        -moz-border-radius: 0 $border-radius-box $border-radius-box $border-radius-box;
        -khtml-border-radius: 0 $border-radius-box $border-radius-box $border-radius-box;
        border-radius: 0 $border-radius-box $border-radius-box $border-radius-box;
        // -webkit-box-shadow: 2px 2px $box-shadow-dark;
        // -moz-box-shadow: 2px 2px $box-shadow-dark;
        // box-shadow: 2px 2px $box-shadow-dark;
    }
}

.ua-horizontal-tab {
    .ua-tab-item {
        margin-right: 0;
        padding: 0;
        border-bottom-width: 0;
        -webkit-box-shadow: 2px 0 2px 0 $box-shadow-dark;
        -moz-box-shadow: 2px 0 2px 0 $box-shadow-dark;
        box-shadow: 2px 0 2px 0 $box-shadow-dark;

        &:hover,
        &:focus {
            border-bottom-width: 0;
            color: $colour-light-grey;

            > span {
                border-color: $colour-white;
            }
        }
        &.active {
            border-bottom-width: 0;
            color: $colour-black;

            &:hover,
            &:focus {
                border-bottom-width: 0;
                color: $colour-dark-black;

                > span {
                    background-color: $colour-white;
                    border-color: $colour-white;
                    color: $colour-dark-black;
                }
            }
            > span {
                border-color: $colour-light-grey;
                color: $colour-black;
            }
        }
        &:first-child {
            > span {
                border-left-width: $width-border;
                border-left-style: solid;
            }
        }
        > span {
            margin-right: 0;
            border: $width-border solid $colour-light-grey;
            border-bottom: none;
            color: $colour-light-grey;
            -webkit-border-radius: $border-radius-box $border-radius-box 0 0;
            -moz-border-radius: $border-radius-box $border-radius-box 0 0;
            -khtml-border-radius: $border-radius-box $border-radius-box 0 0;
            border-radius: $border-radius-box $border-radius-box 0 0;
            font-size: 14.5px;
            text-align: center;
        }
    }
    &-pane {
        -webkit-border-radius: 0 0 $border-radius-box $border-radius-box;
        -moz-border-radius: 0 0 $border-radius-box $border-radius-box;
        -khtml-border-radius: 0 0 $border-radius-box $border-radius-box;
        border-radius: 0 0 $border-radius-box $border-radius-box;
        // -webkit-box-shadow: 2px 2px $box-shadow-dark;
        // -moz-box-shadow: 2px 2px $box-shadow-dark;
        // box-shadow: 2px 2px $box-shadow-dark;
    }
}

/* PROGRESS */
.ua-progress {
    position: relative;
    width: 100%;
    height: 6px;
    margin-bottom: 30px;
    -webkit-border-radius: $border-radius-progress;
    -moz-border-radius: $border-radius-progress;
    -khtml-border-radius: $border-radius-progress;
    border-radius: $border-radius-progress;
    overflow: hidden;

    .ua-progress-bar {
        position: absolute;
        width: 0%;
        height: 100%;
        background-color: $colour-orange;
        -webkit-border-radius: $border-radius-progress;
        -moz-border-radius: $border-radius-progress;
        -khtml-border-radius: $border-radius-progress;
        border-radius: $border-radius-progress;
        float: left;
        -webkit-box-shadow: inset 0 -1px 0 $box-shadow-dark;
        -moz-box-shadow: inset 0 -1px 0 $box-shadow-dark;
        box-shadow: inset 0 -1px 0 $box-shadow-dark;
        line-height: 20;
        text-align: center;
        transition: 1s linear;
    }
}

/* HANDLING */
.ua-handling {
    z-index: 5;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;

    > div {
        height: 100%;
        margin: 10px;

        &.row {
            align-items: center;
            justify-content: center;
        }
    }
    &.show {
        display: block;
    }
    &-content {
        padding: 30px;
        text-align: center;
    }
}

/* TAG */
.tag-sorter {
    &.product {
        margin-bottom: 0;
    }
    &.vertical {
        margin-bottom: 30px;
        text-align: left;

        .tag-btn {
            display: block;
        }
    }
    .tag-btn {
        display: inline-block;
        margin-right: 10px;
        padding: 7.05px;
        border: $width-border solid $colour-orange;
        color: $colour-orange;
        font-size: 13.5px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:focus {
            border: $width-border solid $colour-light-orange;
            color: $colour-light-orange;
        }
        &.active {
            background-color: $colour-orange;
            border: 2px solid $colour-orange;
            color: $colour-white;
            font-weight: 400;
        }
    }
}

.dashboard-toolbar {
    .tag-sorter {
        width: auto;
        display: inline-block;
        margin-left: 20px;

        .tag-btn {
            margin-right: 10px;
            padding: 7.5px;
        }
    }
}

/* ICON */
.fa {
    vertical-align: baseline;
}
.elegant {
    vertical-align: middle;
}
.show-more-icon {
    font-size: 1.5em;
}

/* SHAPE */
.ua-check::after {
    color: $colour-orange;
    content: "\f00c";
    font-size: 20px;
    font-family: $font-family-awesome;
    font-weight: 900;
    margin-top: -5px;
    z-index: 1;
}
.ua-circle {
    z-index: 1;
    position: absolute;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    background-color: $colour-orange;
    -webkit-border-radius: $border-radius-circle;
    -moz-border-radius: $border-radius-circle;
    -khtml-border-radius: $border-radius-circle;
    border-radius: $border-radius-circle;
    color: $colour-orange;
}
.ua-triangle {
    z-index: 1;
    position: absolute;
    top: 27%;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 9.5px solid $colour-grey;
    border-right: 9.5px solid transparent;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);

    &.active {
        border-top-color: $colour-orange;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

/* WIDGET */
// .clearfix {
//     zoom: 1;
//
//     &::before {
//         content: "\0020";
//         display: block;
//         height: 0;
//         font-size: 0;
//         overflow: hidden;
//         line-height: 0;
//         visibility: hidden;
//     }
//     &::after {
//         content: "\0020";
//         display: block;
//         height: 0;
//         font-size: 0;
//         overflow: hidden;
//         line-height: 0;
//         visibility: hidden;
//         clear: both;
//     }
// }

/* MESSAGE BOX */
.message-box {
    &-container {
        position: relative;
        top: 35%;
        width: 100%;
        padding: 20px 15px 30px;
        background-color: $colour-dark-grey;
        border-top: $width-border solid $border-light;
        border-bottom: $width-border solid $border-light;
        -webkit-box-shadow: 3px 0 3px 0 $box-shadow-light;
        -moz-box-shadow: 3px 0 3px 0 $box-shadow-light;
        box-shadow: 3px 0 3px 0 $box-shadow-light;
        color: $colour-white;
    }
    &-content {
        position: relative;
        left: 20%;
        width: 60%;

        input,
        select {
            width: 50%;
            padding: 5px;
        }
        .meg-title {
            margin-bottom: 10px;
            font-size: 20px;
            letter-spacing: -1px;

            i {
                margin-bottom: 3px;
            }
        }
        .p-text {
            margin-top: 10px;
            font-size: 13.5px;
        }
        .btn-section {
            width: 100%;
            height: 30px;
            margin-top: 30px;

            button {
                margin: 0;
                margin-right: 7px;
                padding-bottom: 7px;
                padding-left: 16px;
                padding-right: 12px;
                padding-top: 7px;
            }
        }
    }
}

#divMiniIcons {
    z-index: 9999;
    position: fixed;
    width: 415px;
    right: 10px;
    bottom: 180px;
    float: right;

    .cajita {
        display: block;
        margin-top: 5px;
        margin-left: 4px;
        padding: 4px 6px;
        background-color: $colour-red;
        color: $colour-white;
        font-size: 17px;
        float: right;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &:active {
            position: relative;
            top: 1px;
            left: 1px;
        }
        img {
            width: 23px;
            height: 23px;
            padding-top: 3px;
            padding-left: 3px;
        }
    }
}
#divSmallBoxes {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
}

.btn-close {
    display: block;
    position: absolute;
    top: 15px;
    right: 10px;
    width: 15px;
    height: 16px;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.5;

    &:hover,
    &:focus
    &:active, {
        opacity: 1;
    }
}
.big-box {
    z-index: 9999;
    position: fixed;
    width: 390px;
    height: 150px;
    bottom: 10px;
    right: 10px;
    padding: 10px 10px 5px;
    background-color: $colour-orange;
    border-left: 5px solid $border-dark;
    color: $colour-white;
    overflow: hidden;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;

    p {
        margin-top: 10px;
        font-size: 13.5px;
    }
    span {
        display: block;
        padding: 5px 0;
        font-size: 17px;
        font-weight: 300;
        letter-spacing: -1px;
    }
    &-icon {
        z-index: 0;
        position: absolute;
        top: 120px;
        left: 6px;
        font-size: 30px;
        text-align: left;
    }
    &-number {
        width: 100%;
        font-size: 25px;
        text-align: right;
    }
}
.small-box {
    z-index: 9999;
    position: absolute;
    top: 50px;
    right: 5px;
    width: 420px;
    border: $width-border solid transparent;
    color: $colour-white;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        border: $width-border solid $border-light;
        -webkit-box-shadow: 3px 0 3px 0 $box-shadow-light;
        -moz-box-shadow: 3px 0 3px 0 $box-shadow-light;
        box-shadow: 3px 0 3px 0 $box-shadow-light;
    }
    .photo {
        position: absolute;
        left: 17px;
        font-size: 50px;
    }
    .text-area {
        width: 93%;
        float: left;
        padding-left: 20px;

        p {
            &.content {
                margin-top: 2px;
                font-size: 13.5px;
            }
        }
        span {
            &.title {
                display: block;
                margin: 6px auto;
                font-size: 16px;
                font-weight: 300;

                i {
                    vertical-align: baseline;
                }
                p {
                    display: inline-block;
                }
            }
        }
    }
}
.mini {
    &-pic {
        position: absolute;
        bottom: 8px;
        right: 9px;
    }
    &-icon-o {
        height: 100%;
        font-size: 20px;
    }
}

/* DROPZONE */
.dropzone-container {
    min-height: 200px;
    margin: 10px 0;
    padding: 20px;
    background: $colour-transparent;
    border-width: $width-border;
    border-style: dashed;
    -webkit-border-radius: $border-radius-box;
    -moz-border-radius: $border-radius-box;
    -khtml-border-radius: $border-radius-box;
    border-radius: $border-radius-box;

    .dropzone {
        width: 100%;
        height: 100%;
        cursor: pointer;
        box-sizing: border-box;

        * {
            cursor: pointer;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
    .preview {
        position: relative;
        min-height: 100px;

        &-img {
            @include animation-name(slideIn);
            @include animation-detail(slideIn);
            z-index: 10;
            display: block;
            height: 150px;
            max-height: 100%;
            width: auto;
            margin: 0 auto;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -khtml-border-radius: 10px;
            border-radius: 10px;
            overflow: hidden;
            -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            -moz-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            -ms-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            -o-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
        }
        .remove-files {
            display: block;
            margin-top: 20px;
            font-size: 1.6rem;
            cursor: pointer;
            text-align: center;

            &:hover {
                -webkit-transform: scale($scale);
                -moz-transform: scale($scale);
                -ms-transform: scale($scale);
                -o-transform: scale($scale);
                transform: scale($scale);
            }
        }
    }
}

/* UTIL */
.ua-backdrop {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    // modal
    &.modal-backdrop {
        z-index: 1;
    }
    // tooltip
    &.tooltip-backdrop {
        z-index: 20;
    }
    // laboratory
    &.lab-setting-backdrop {
        z-index: 905;
    }
}

body {
    &.iOS {
        .ua-backdrop {
            &.lab-setting-backdrop,
            &.modal-backdrop {
                z-index: -1;
            }
        }
    }
    &.Mobile {
        .ua-backdrop {
            &.tooltip-backdrop {
                z-index: 10;
                width: 100vw;
                height: 100vh;
            }
        }
    }
}

.frame {
    position: relative;
    background-color: $colour-transparent;
    overflow: hidden;
    vertical-align: middle;
}

// /* POSITION */
// .position {
//     &-absolute {
//         position: absolute;
//     }
// }

/* DISPLAY */
.display {
    &-block {
        display: block;
    }
    &-flex {
        display: flex;
        align-items: center;
    }
    &-inline {
        display: inline;
    }
    &-inline-block {
        display: inline-block;
    }
    &-none {
        display: none;
    }
}

.flex{
    display:flex;
    &-jc{
        &-start{
            justify-content: flex-start;
        }
        &-c{
            justify-content: center;
        }
        &-end{
            justify-content: flex-end;
        }
        &-sb{
            justify-content: space-between;
        }
    }
    &-ai{
        &-start{
            align-items: flex-start;
        }
        &-c{
            align-items: center;
        }
        &-end{
            align-items: flex-end;
        }
    }
}

/* WIDTH & HEIGHT */
.width {
    &-15 {
        width: 15px;
    }
    &-19 {
        width: 19px;
    }
    &-25 {
        width: 25px;
    }
    &-35 {
        width: 35px;
    }
    &-150 {
        width: 150px;
    }
    &-max-content {
        width: max-content !important;
    }
}

.w-full{
    width: 100%;
}

.height {
    &-0 {
        height: 0;
    }
    &-15 {
        height: 15px;
    }
    &-25 {
        height: 25px;
    }
    &-36 {
        height: 36px;
    }
    &-45 {
        height: 45px;
    }
    &-120 {
        height: 120px;
    }
    &-200 {
        height: 200px;
    }
    &-220 {
        height: 220px;
    }
    &-350 {
        height: 350px;
    }
}

/* MARGIN & PADDING */
.margin {
    &-0 {
        margin: 0;
    }
    &-5 {
        margin: 5px;
    }
    &-10 {
        margin: 10px;
    }
    &-15 {
        margin: 15px;
    }
    &-vertical {
        &-10 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        &-15 {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &-20 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        &-30 {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &-50 {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    &-horizontal {
        &-0 {
            margin-left: 0;
            margin-right: 0;
        }
        &-2 {
            margin-left: 2px;
            margin-right: 2px;
        }
        &-5 {
            margin-left: 5px;
            margin-right: 5px;
        }
        &-15 {
            margin-left: 15px;
            margin-right: 15px;
        }
        &-20 {
            margin-left: 20px;
            margin-right: 20px;
        }
        &-25 {
            margin-left: 25px;
            margin-right: 25px;
        }
        &-auto {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &-auto {
        margin: auto;
    }
    &-top {
        &-0 {
            margin-top: 0;
        }
        &-4 {
            margin-top: 4px;
        }
        &-5 {
            margin-top: 5px;
        }
        &-10 {
            margin-top: 10px;
        }
        &-13 {
            margin-top: 13px;
        }
        &-15 {
            margin-top: 15px;
        }
        &-20 {
            margin-top: 20px;
        }
        &-30 {
            margin-top: 30px;
        }
        &-50 {
            margin-top: 50px;
        }
        &-60 {
            margin-top: 60px;
        }
        &-10vh {
            margin-top: 10vh;
        }
    }
    &-left {
        &-0 {
            margin-left: 0;
        }
        &-2 {
            margin-left: 2px;
        }
        &-5 {
            margin-left: 5px;
        }
        &-8 {
            margin-left: 8px;
        }
        &-10 {
            margin-left: 10px;
        }
        &-13 {
            margin-left: 13px;
        }
        &-15 {
            margin-left: 15px;
        }
        &-auto {
            margin-left: auto;
        }
    }
    &-bottom {
        &-0 {
            margin-bottom: 0;
        }
        &-5 {
            margin-bottom: 5px;
        }
        &-10 {
          margin-bottom: 10px;
        }
        &-15 {
            margin-bottom: 15px;
        }
        &-20 {
            margin-bottom: 20px;
        }
        &-30 {
            margin-bottom: 30px;
        }
        &-50 {
            margin-bottom: 50px;
        }
    }
    &-right {
        &-2 {
            margin-right: 2px;
        }
        &-5 {
            margin-right: 5px;
        }
        &-10 {
            margin-right: 10px;
        }
        &-13 {
            margin-right: 13px;
        }
        &-15 {
            margin-right: 15px;
        }
        &-18 {
            margin-right: 18px;
        }
        &-20 {
            margin-right: 20px;
        }
        &-30 {
            margin-right: 30px;
        }
        &-auto {
            margin-right: auto;
        }
    }
}
.padding {
    &-0 {
        padding: 0;
    }
    &-5 {
        padding: 5px;
    }
    &-10 {
        padding: 10px;
    }
    &-15 {
        padding: 15px;
    }
    &-20 {
        padding: 20px;
    }
    &-25 {
        padding: 25px;
    }
    &-vertical {
        &-2 {
            padding-top: 2px;
            padding-bottom: 2px;
        }
        &-5 {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    &-horizontal {
        &-0 {
            padding-left: 0;
            padding-right: 0;
        }
        &-5 {
            padding-left: 5px;
            padding-right: 5px;
        }
        &-10 {
            padding-left: 10px;
            padding-right: 10px;
        }
        &-15 {
            padding-left: 15px;
            padding-right: 15px;
        }
        &-20 {
            padding-left: 20px;
            padding-right: 20px;
        }
        &-25 {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    &-top {
        &-0 {
            padding-top: 0;
        }
        &-2 {
            padding-top: 2px;
        }
        &-10 {
            padding-top: 10px;
        }
        &-15 {
            padding-top: 15px;
        }
        &-20 {
            padding-top: 20px;
        }
        &-50 {
            padding-top: 50px;
        }
    }
    &-right {
        &-5 {
            padding-right: 5px;
        }
        &-10 {
            padding-right: 10px;
        }
        &-12 {
            padding-right: 12px;
        }
        &-15 {
            padding-right: 15px;
        }
    }
    &-bottom {
        &-2 {
            padding-bottom: 2px;
        }
        &-10 {
            padding-bottom: 10px;
        }
        &-15 {
            padding-bottom: 15px;
        }
        &-20 {
            padding-bottom: 20px;
        }
    }
    &-left {
        &-5 {
            padding-left: 5px;
        }
        &-10 {
            padding-left: 10px;
        }
        &-15 {
            padding-left: 15px;
        }
    }
}

/* BORDER */
.border-radius {
    &-12 {
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -khtml-border-radius: 12px;
        border-radius: 12px;
    }
    &-20 {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -khtml-border-radius: 20px;
        border-radius: 20px;
    }
}

/* COLOUR */
.bg {
    &-black {
        background-color: rgba(0, 0, 0, 0.5);
    }
    &-blue {
        background-color: $colour-blue;
    }
    &-light-blue {
        background-color: $colour-light-blue;
    }
    &-dark-grey {
        background-color: $colour-dark-grey;
    }
    &-grey {
        background-color: $colour-grey;
    }
    &-light-grey {
        background-color: $colour-light-grey;
    }
    &-green {
        background-color: $colour-green;
    }
    &-magenta {
        background-color: $colour-magenta;
    }
    &-light-magenta {
        background-color: $colour-light-magenta;
    }
    &-light-orange {
        background-color: $colour-light-orange;
    }
    &-orange {
        background-color: $colour-orange;
    }
    &-red {
        background-color: $colour-red;
    }
    &-transparent {
        background-color: $colour-transparent;
    }
    &-white {
        background-color: $colour-white;
    }
}
.outline {
    &-blue {
        border: $width-border solid $colour-blue;
        background-color: $colour-transparent;
        color: $colour-blue;
    }
    &-light-blue {
        border: $width-border solid $colour-light-blue;
        background-color: $colour-transparent;
        color: $colour-light-blue;
    }
    &-dark-grey {
        border: $width-border solid $colour-dark-grey;
        background-color: $colour-transparent;
        color: $colour-dark-grey;
    }
    &-grey {
        border: $width-border solid $colour-grey;
        background-color: $colour-transparent;
        color: $colour-grey;
    }
    &-light-grey {
        border: $width-border solid $colour-light-grey;
        background-color: $colour-transparent;
        color: $colour-light-grey;
    }
    &-green {
        border: $width-border solid $colour-green;
        background-color: $colour-transparent;
        color: $colour-green;
    }
    &-light-magenta {
        border: $width-border solid $colour-light-magenta;
        background-color: $colour-transparent;
        color: $colour-light-magenta;
    }
    &-magenta {
        border: $width-border solid $colour-magenta;
        background-color: $colour-transparent;
        color: $colour-magenta;
    }
    &-light-orange {
        border: $width-border solid $colour-light-orange;
        background-color: $colour-transparent;
        color: $colour-light-orange;
    }
    &-orange {
        border: $width-border solid $colour-orange;
        background-color: $colour-transparent;
        color: $colour-orange;
    }
    &-red {
        border: $width-border solid $colour-red;
        background-color: $colour-transparent;
        color: $colour-red;
    }
    &-transparent {
        border: none;
    }
    &-white {
        border: $width-border solid $colour-white;
        background-color: $colour-transparent;
        color: $colour-white;
    }
}
.txt {
    &-black {
        color: $colour-black;
    }
    &-blue {
        color: $colour-blue;
    }
    &-dark-blue {
        color: $colour-dark-blue;
    }
    &-light-blue {
        color: $colour-light-blue;
    }
    &-green {
        color: $colour-green;
    }
    &-dark-grey {
        color: $colour-dark-grey;
    }
    &-grey {
        color: $colour-grey;
    }
    &-light-grey {
        color: $colour-light-grey;
    }
    &-light-magenta {
        color: $colour-light-magenta;
    }
    &-magenta {
        color: $colour-magenta;
    }
    &-light-orange {
        color: $colour-light-orange;
    }
    &-orange {
        color: $colour-orange;
    }
    &-red {
        color: $colour-red;
    }
    &-transparent {
        color: $colour-transparent;
    }
    &-turquoise {
      color: $colour-turquoise;
    }
    &-white {
        color: $colour-white;
    }
}

/* OPACITY */
.opacity-50 {
    opacity: 0.5;
}
.opacity-80 {
    opacity: 0.8;
}

/* CURSOR */
.cursor {
    &-alias {
        cursor: alias;
    }
    &-all-scroll {
        cursor: all-scroll;
    }
    &-auto {
        cursor: auto;
    }
    &-cell {
        cursor: cell;
    }
    &-col-resize {
        cursor: col-resize;
    }
    &-context-menu {
        cursor: context-menu;
    }
    &-copy {
        cursor: copy;
    }
    &-crosshair {
        cursor: crosshair;
    }
    &-default {
        cursor: default;
    }
    &-e-resize {
        cursor: e-resize;
    }
    &-ew-resize {
        cursor: ew-resize;
    }
    &-help {
        cursor: help;
    }
    &-move {
        cursor: move;
    }
    &-n-resize {
        cursor: n-resize;
    }
    &-ne-resize {
        cursor: ne-resize
    }
    &-nesw-resize {
        cursor: nesw-resize;
    }
    &-no-drop {
        cursor: no-drop;
    }
    &-none {
        cursor: none;
    }
    &-not-allowed {
        cursor: not-allowed;
    }
    &-ns-resize {
        cursor: ns-resize;
    }
    &-nw-resize {
        cursor: nw-resize;
    }
    &-nwse-resize {
        cursor: nwse-resize;
    }
    &-pointer {
        cursor: pointer;
    }
    &-progress {
        cursor: progress;
    }
    &-row-resize {
        cursor: row-resize;
    }
    &-s-resize {
        cursor: s-resize;
    }
    &-se-resize {
        cursor: se-resize;
    }
    &-sw-resize {
        cursor: sw-resize;
    }
    &-text {
        cursor: text;
    }
    &-vertical-text {
        cursor: vertical-text;
    }
    &-w-resize {
        cursor: w-resize;
    }
    &-wait {
        cursor: wait;
    }
}

/* FLOAT */
.pull {
    &-left {
        float: left;
    }
    &-right {
        float: right;
    }
}

/* OVERFLOW */
.overflow {
    // &-hidden {
    //     overflow: hidden;
    // }
    &-y-auto {
        overflow-y: auto;
    }
}

/* TXT */
.txt {
    &-extra-small {
        font-size: 11px;
    }
    &-small {
        font-size: 13.5px;
    }
    &-medium {
        font-size: 18px;
    }
    &-large {
        font-size: 24px;
    }
    &-20 {
        font-size: 20px;
    }
    &-5em {
        font-size: 5em;
    }
    &-light {
        font-weight: 300;
    }
    &-normal {
        font-weight: 400;
    }
    &-semibold {
        font-weight: 500;
    }
    &-bold {
        font-weight: 600;
    }
    &-line-through {
        text-decoration: line-through;
    }
    &-upper-case {
        text-transform: uppercase;
    }
}
.vertical-align {
    &-baseline {
        vertical-align: baseline;
    }
    &-bottom {
        vertical-align: bottom;
    }
    &-middle {
        vertical-align: middle;
    }
    &-top {
        vertical-align: top;
    }
}
.word-break-all {
    word-break: break-all;
}
.white-space-nowrap {
    white-space: nowrap;
}

/* IMAGE & PHOTO */
.img-circle {
    -webkit-border-radius: $border-radius-circle;
    -moz-border-radius: $border-radius-circle;
    -khtml-border-radius: $border-radius-circle;
    border-radius: $border-radius-circle;
}
.no-img img {
    display: none;
}

body {
    &.Mobile {
        .ua-tab {
            > li {
                > span {
                    font-size: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .ua-form {
        .inputs {
            margin: 8px 0;
        }
    }
    .tab-container {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 0;
        }
    }

    .ua-tab {
        display: initial;
        width: calc(100% - 35px);
        float: none;

        &:first-child {
            margin-left: 0;
        }
        &-item {
            width: 30%;

            &:first-child,
            &:last-child {
                width: 20%;
            }
            .ua-tab-title {
                padding: 10px;

                > * {
                    vertical-align: middle;
                }
            }
            .ua-circle {
                position: relative;
                top: 30px;
                left: 10px;
                margin: 0;
            }
        }
        &-pane {
            width: 100%;
            min-height: 100px;
            margin-left: 0;
            padding-bottom: 50px;
            -webkit-border-radius: 0 0 $border-radius-box $border-radius-box;
            -moz-border-radius: 0 0 $border-radius-box $border-radius-box;
            -khtml-border-radius: 0 0 $border-radius-box $border-radius-box;
            border-radius: 0 0 $border-radius-box $border-radius-box;

            .pagination {
                margin: 0;
            }
        }
        &.ua-vertical-tab {
            .ua-tab-item {
                display: inline-table;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                .ua-circle{
                    top: 27px;
                    left: 8px;
                }
                &.active {
                    margin-left: 0;

                    &:hover,
                    &:focus {
                        margin-left: 0;
                    }
                }
                &:not(:first-child) {
                    > span {
                        border-width: $width-border;
                        border-style: solid;
                        border-bottom: none;
                    }
                }
                > span {
                    padding-left: 5vw;
                    border-width: $width-border;
                    border-style: solid;
                    border-bottom: none;
                    -webkit-border-radius: $border-radius-box $border-radius-box 0 0;
                    -moz-border-radius: $border-radius-box $border-radius-box 0 0;
                    -khtml-border-radius: $border-radius-box $border-radius-box 0 0;
                    border-radius: $border-radius-box $border-radius-box 0 0;
                    font-size: 13px;
                }
            }
        }
        &.ua-horizontal-tab {
            display: block;

            li {
                width: fit-content;
                margin: 0;

                > span {
                    margin: 0;
                    padding: 10px;
                }
            }
        }
    }
    .ua-circle {
        margin-top: 7.5px;
        margin-left: -20px;
    }
}

@media only screen and (min-width: 520px) and (max-width: 1006px) {
    .tag-sorter {
        display: block;
        margin: auto;
    }
    .ua-app.menu-hidden {
        .tag-sorter {
            display: inline-block;
            margin-left: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    #divSmallBoxes {
        width: 90%;
    }

    .message-box {
        &-container {
            top: 25%;
        }
        &-content {
            width: 100%;
            left: 0;
            padding: 3px;
        }
    }

    .big-box {
        width: 88%;
    }
    .small-box {
        width: 95%;

        .text-area {
            width: 93%;
            padding-left: 20px;
            float: left;
        }
    }
}

.remark{
    &-red{
        color: #ff7979;
        font-weight: 500;
    }

    &-green{
        color: #02e100;
        font-weight: 500;
    }
}

.fade{
	position: relative;
}

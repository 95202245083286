@import "../config";

// btn
.renew {
    &.dashboard-renew {
        position: absolute;
        top: 9px;
        right: 15px;
    }

    .ua-btn.default.renew-btn {
        margin: 0;
        background-color: $colour-red;
        border-color: $colour-red;
    }
}

.ua-app .fixed-bar .toolbar > .renew {
    line-height: unset;
}

// modal
.modal {
    &.renew-modal {
        .renew-content {
            max-width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .case-list {
                .case-item {
                    border: $width-border solid $colour-grey;
                    -webkit-border-radius: $border-radius-box;
                    -moz-border-radius: $border-radius-box;
                    -khtml-border-radius: $border-radius-box;
                    border-radius: $border-radius-box;
                    overflow: hidden;

                    .case-title {
                        margin: 0;
                        background-color: $colour-dark-grey;
                        border-bottom: $width-border solid $colour-grey;
                        font-weight: 500;
                    }
                    .case-content {
                        &-discount,
                        &-bonus,
                        &-bonus .bonus-list > p {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

body {
    &.Mobile {
        // btn
        .renew {
            &.dashboard-renew {
                position: absolute;
                top: 14px;
                right: 52px;
                float: none;

                .renew-btn {
                    padding: 6px;
                }
            }
        }
        .modal {
            &.renew-modal {
                .renew-content {
                    .case-list {
                        .case-item {
                            .case-content {
                                &-bonus,
                                &-bonus .bonus-list > p,
                                &-bonus .bonus-list > p > small {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

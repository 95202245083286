@import "../animation";
@import "../config";

// trigger
.ua-tooltip-trigger {
    color: $colour-orange;

    &:hover,
    &:focus {
        color: $colour-light-orange;
        -webkit-transform: scale($scale);
        -moz-transform: scale($scale);
        -ms-transform: scale($scale);
        -o-transform: scale($scale);
        transform: scale($scale);
    }
    &.active {
        color: $colour-light-orange;
        -webkit-transform: scale($scale);
        -moz-transform: scale($scale);
        -ms-transform: scale($scale);
        -o-transform: scale($scale);
        transform: scale($scale);

        span {
            color: $colour-white;
        }
    }
}

// tooltip
.ua-box {
    &.ua-tooltip {
        @include animation-name(flipInX);
        @include animation-detail(flipInX);
        @include animated;
        z-index: 50;
        position: absolute;
        width: 250px;
        padding: 15px;
        background-color: $colour-white;
        border-color: $colour-white;
        color: $colour-black;
        font-size: 13.5;

        ul {
            margin-bottom: 0;
            padding: 0;
            list-style: none;
            cursor: pointer;
        }
        li {
            padding-top: 1px;
            padding-bottom: 1px;

            &.link-name {
                padding: 10px 20px;
                border-bottom: $width-border solid $colour-grey;
                text-align: center;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        h5 {
            margin: 8px;
        }
        tr {
            margin: 12px auto;
        }
        .disabled {
            border: none;

            &:hover,
            &:focus {
                border: none;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .ua-box {
        &.ua-tooltip {
            overflow-y: auto;
            -webkit-overflow-scrolling: auto;
        }
    }
}
